import { ChevronLeftRounded } from '@mui/icons-material';
import { Button, Grid, Typography } from '@mui/material';
import { amber, cyan, red } from '@mui/material/colors';
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import fetchForecastManifest from './services/simulated_incidents';
import * as React from 'react';


function getColorFromStatus(status) {
    if (status == 'COMPLETE' || !status) {
        return cyan['A400'];
    }
    if (status == 'SKIPPED') {
        return amber[400]
    }
    if (status == 'ERROR') {
        return red[500];
    }
    return amber[500]
};

const m2ToAcreage = (val) => {
    if (!val || isNaN(val)) {
        return 0;
    }
    return (val * 0.000247105).toFixed(0)
}

const computeAreaIncrease = (a1, a2) => {
    if (!a1 || isNaN(a1) || !a2 || isNaN(a2)) {
        return 0;
    }
    return (((a2 - a1) / a1) * 100).toFixed(0)
}

function toTitleCase(str) {
    return str.replace(
        /\w\S*/g,
        function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}

export default function IncidentList() {
    const [manifest, setManifest] = useState([]);
    const [dt, setDt] = useState(null);
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchManifest = async () => {
            setLoading(true);
            let data = await fetchForecastManifest();
            data.forEach((datum) => {
                datum.incident_name = toTitleCase(datum.incident_name);
                datum.initialAreaFormat = (datum.area_timeseries.values[0].mean_size * 0.000247105).toLocaleString(undefined,
                    { 'minimumFractionDigits': 0, 'maximumFractionDigits': 0 });
                datum.forecast_area_format = (datum.forecast_area * 0.000247105).toLocaleString(undefined,
                    { 'minimumFractionDigits': 0, 'maximumFractionDigits': 0 });
            })
            data = data.sort((a, b) => {
                return b.forecast_area - a.forecast_area
            })
            setManifest(data);
            setLoading(false);
        };
        fetchManifest();
    }, []);

    const columns = [
        {
            name: "irwin_id",
            label: "Incident ID",
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            name: "incident_name",
            label: "Incident Name",
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            name: "incident_description",
            label: "Description",
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            name: "state",
            label: "State",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "initialAreaFormat",
            label: "Current Estimated Area",
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            name: "forecast_area_format",
            label: "48 Hour Forecast Area",
            options: {
                filter: false,
                sort: true,
            }
        }
    ];

    return <Grid container sx={{ maxHeight: '100vh', overflowY: 'auto' }}>
        <Grid item xs={12} sx={{ mx: 2 }}>
            <Button onClick={()=>{navigate(-1)}} startIcon={<ChevronLeftRounded/>}>Back</Button>
        </Grid>
        <Grid item xs={12} sx={{ mt: 2, mx: 8 }}>
            <Typography variant='h4'>
                Current Large Incidents
            </Typography>
            <Typography variant='subtitle1'>
                {dt}
            </Typography>
        </Grid>
        <Grid item xs={4} sx={{ mx: 2, mx: 8 }}>
            <Typography variant='subtitle1'>
                Current incidents are mapped by the National Interagency Fire Center (NIFC).
                Willow forecasts their near-term growth once per day. Click on an incident below to get information on its current and expected growth.
            </Typography>
            <Typography variant='caption'>Beta</Typography>
        </Grid>
        <Grid item xs={12} sx={{ mx: 2, mx: 8, py: 6 }}>
            {loading ? (
                <Typography variant='h6'>Loading the most recent major wildfire forecasts...</Typography>
            ) :
                <MUIDataTable
                    title={"Large Incidents"}
                    data={manifest}
                    columns={columns}
                    options={{
                        pagination: true,
                        rowsPerPage: 100,
                        print: false,
                        onRowClick: (rowData) => {
                            navigate(`/app/incidents/${rowData[0]}/map`)
                        }
                    }}
                />
            }
        </Grid>






        {/* <Table sx={{ my: 2, mx:8 }}> */}
        {/* <TableHead> */}
        {/* <TableRow sx={{ backgroundColor: 'gray' }}>
                    <TableCell><Typography variant='subtitle1'>ID</Typography></TableCell>
                    <TableCell><Typography variant='subtitle1'>Name</Typography></TableCell>
                    <TableCell><Typography variant='subtitle1'>Location</Typography></TableCell>
                    <TableCell><Typography variant='subtitle1'>State</Typography></TableCell>
                    <TableCell><Typography variant='subtitle1'>Reported Behavior</Typography></TableCell>
                    <TableCell><Typography variant='subtitle1'>Forecast</Typography></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {manifest.map((item) => {
                    return <TableRow key={item.irwin_id}>
                        <TableCell>{item.irwin_id}</TableCell>
                        <TableCell>{item.incident_name.toUpperCase()}</TableCell>
                        <TableCell>{item.incident_description}</TableCell>
                        <TableCell>{item.state.split("-")[1]}</TableCell>
                        <TableCell>{item.behavior}</TableCell>
                        <TableCell><Link to={`/app/incidents/${item.irwin_id}/map`}><MapTwoTone></MapTwoTone></Link></TableCell>
                    </TableRow>
                })}
            </TableBody>
        </Table> */}
    </Grid>
}