
import Error404 from './404';
import './App.css';
import Auth0ProviderWithHistory from './components/AuthProvider';
import DashboardLayout from './DashboardLayout';
import HomeView from './Home';
import PrimaryLayout from './PrimaryLayout';
import Splash from './Splash';
import * as React from 'react';

import {
    BrowserRouter, Route, Routes
} from "react-router-dom";



import IncidentDetails from './IncidentDetails';
import IncidentList from './Incidents';
import MonitoringPolicyDetails from './MonitoringProperties';
import MonitoringView from './MonitoringView';
import ActivationCodeChecker from './ActivationCode';
import LogoutPage from './components/logoutPage';
import InsetMap from './components/InsetMap';
import GroundtruthSnapshotView from './GroundtruthView';
import GroundtruthList from './GroundtruthList';
import TokenListView from './TokenListView';

function Router() {

    return (

        <BrowserRouter>
            <Auth0ProviderWithHistory>
                <Routes>
                    <Route key={'root-link'} path="/" element={<Splash />} ></Route>
                    <Route key={'app-link'} path="/app" element={<PrimaryLayout />}>
                        <Route key={'home-link'} path="" element={<HomeView />}></Route>
                        <Route key={'monitoring-link'} path="monitoring/" element={<MonitoringView></MonitoringView>}></Route>
                        <Route key={'monitoring-link'} path="monitoring/:policyID" element={<MonitoringPolicyDetails></MonitoringPolicyDetails>}></Route>
                        <Route key={'incident-link'} path="incidents/" element={<IncidentList></IncidentList>}></Route>
                        <Route key={'incident-details-link'} path="incidents/:incidentID/:tabID" element={<IncidentDetails></IncidentDetails>}></Route>
                        <Route key={'dash-link'} path="dashboard/:tabId" element={<DashboardLayout></DashboardLayout>}></Route>
                        <Route key={'groundtruth-list'} path='groundtruth' element={<GroundtruthList></GroundtruthList>}></Route>
                        <Route key={'groundtruth-detail'} path='groundtruth/:incidentID/:dt' element={<GroundtruthSnapshotView></GroundtruthSnapshotView>}></Route>
                        <Route key={'token-list'} path='tokens' element={<TokenListView></TokenListView>}></Route>
                    </Route>
                    <Route key='activate' path='activate' element={<ActivationCodeChecker/>}></Route>
                    <Route key='activate' path='logout' element={<LogoutPage/>}></Route>
                    <Route key='activate' path='inset' element={<InsetMap/>}></Route>
                    <Route key={'err-link'} path="*" element={<Error404></Error404>} />
                </Routes>
            </Auth0ProviderWithHistory>
        </BrowserRouter >

    );
}

export default Router;
