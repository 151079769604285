import { Grid, Paper, Button } from '@mui/material';
import ResultsContext from './context/Results';
import SettingsContext from "./context/Settings";
import MapView from './MapView';
import ToolPanel from './ToolPanel';
import * as React from 'react';
import { ChevronLeft } from '@mui/icons-material';
import defaultResults from "./defaults/results";


export default function DashboardMapWithTools(props) {
    return (
        <ResultsContext.Consumer>
            {({ results, updateResults, structures }) => (
                <SettingsContext.Consumer>
                    {({ settings, updateSettings }) => (
                        <Grid container>
                            {results.perimeters && results.perimeters.features.length ?
                                <Button sx={{ position: 'absolute', top: 12, left: 12, zIndex: 12 }}
                                    variant='contained'
                                    onClick={() => { updateResults(defaultResults) }}
                                    startIcon={<ChevronLeft />}
                                >Back to Tools</Button>
                                : (
                                    <Paper sx={{ position: 'absolute', top: 0, left: 0, zIndex: 12, borderRadius: 5 }}>
                                        <ToolPanel settings={settings} updateSettings={updateSettings} results={results} updateResults={updateResults}></ToolPanel>
                                    </Paper>
                                )}

                            <Grid item xs={12}>
                                <MapView showStructures={props.showStructures} showAssets={props.showAssets}  structures={structures} addIncidents={true}></MapView>
                            </Grid>
                        </Grid>
                    )}
                </SettingsContext.Consumer>
            )}
        </ResultsContext.Consumer>

    )
}