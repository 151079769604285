import { useAuth0 } from '@auth0/auth0-react';
import { Add, ChevronLeftRounded, DeleteForever, Edit, Visibility } from '@mui/icons-material';
import { Button, Grid, Modal, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useEffect, useState, useRef } from 'react';
import CurrencyFormat from 'react-currency-format';
import Moment from 'react-moment';
import { Link, useNavigate } from 'react-router-dom';
import * as React from 'react';

import EditPolicyModal from './EditPolicyModal';
import AddPolicyModal from './AddPolicyModal';

const MONITORING_HOST = `https://api-core-production-63vbfoxayq-uw.a.run.app`
// const MONITORING_HOST = 'http://localhost:8000'



export default function MonitoringView() {
    const [addModalOpen, setAddModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [isFiltered, setIsFiltered] = useState(false);
    const [searchVal, setSearchVal] = useState("");
    const [needsRefresh, setNeedsRefresh] = useState(true);
    const [policies, setPolicies] = useState([]);
    const [editItem, setEditItem] = useState({});

    const { user } = useAuth0();
    const navigate = useNavigate();

    const onAddPolicy = () => {
        setAddModalOpen(true);
    }

    const onFinishAddPolicy = () => {
        setAddModalOpen(false);
        setNeedsRefresh(true);
    }

    const onFinishEditPolicy = () => {
        setEditModalOpen(false);
        setNeedsRefresh(true);
        setEditItem({});
    }

    const loadPolices = async () => {
        const req = await fetch(`${MONITORING_HOST}/policies/${user.email}`);
        const res = await req.json();
        setPolicies(res);
        setNeedsRefresh(false);
    }

    const handlePolicyDelete = async (policyName) => {
        const baseUrl = `${MONITORING_HOST}/policies/${user.email}/${policyName}`;
        const req = await fetch(baseUrl, { method: 'DELETE' });
        const res = await req.json();
        loadPolices()
    }

    const handlePolicyUpdate = async (policy) => {
        setEditModalOpen(true);
        setEditItem(policy);
    }



    useEffect(() => {
        loadPolices();
    }, []);

    useEffect(() => {
        loadPolices();
    }, [needsRefresh]);


    return <Grid container sx={{ mx: 4, maxHeight: '100vh', overflowY: 'auto' }}>
        <Grid item xs={12} sx={{ mx: 2 }}>
            <Button onClick={() => { navigate(-1) }} startIcon={<ChevronLeftRounded />}>Back</Button>
        </Grid>
        <Grid item xs={12} sx={{ m: 1 }}>
            <Typography variant='h3'>Monitoring</Typography>
        </Grid>
        <Grid item xs={12} sm={4} sx={{ m: 1 }}>
            <Typography variant='body1'>
                Monitoring policies let you track the impact of currently-burning wildfires on assets you care about. After uploading a file of locations, Willow will automatically
                track whether those locations are likely to be involved in a wildfire within the next 48-72 hours and alert you via email.
            </Typography>
        </Grid>
        <Grid item xs={12} sx={{ m: 1 }}>
            <Button variant='contained' color='success' component="label" onClick={onAddPolicy} startIcon={<Add />}>
                Add Policy
            </Button>
        </Grid>

        <Table sx={{ m: 1 }}>
            <TableHead>
                <TableRow>
                    <TableCell>Policy ID</TableCell>
                    <TableCell>Policy Name</TableCell>
                    <TableCell>Created</TableCell>
                    <TableCell>Distance Threshold</TableCell>
                    <TableCell>Number of Assets</TableCell>
                    <TableCell>Total Value</TableCell>
                    <TableCell></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {policies.map((item) => {
                    return <TableRow key={item.created_at}>
                        <TableCell>
                            {item.id}
                        </TableCell>
                        <TableCell>
                            {item.name}
                        </TableCell>
                        <TableCell>
                            <Moment format={"MMMM Do YYYY, h:mm:ss a"} local>{item.created_at}</Moment>
                        </TableCell>
                        <TableCell>
                            {item.distance_threshold} meters
                        </TableCell>
                        <TableCell>
                            {item.property_count}
                        </TableCell>
                        <TableCell>
                            <CurrencyFormat value={item.total_value} displayType={'text'} thousandSeparator={true} prefix={'$'} ></CurrencyFormat>
                        </TableCell>
                        <TableCell>
                            {(item.status && item.status === 'ACTIVE') ? (
                                <>
                                    <Button size='sm' startIcon={<Edit onClick={() => { handlePolicyUpdate(item) }}></Edit>}></Button>
                                    <Button size='sm' startIcon={<DeleteForever onClick={() => { handlePolicyDelete(item.id) }}></DeleteForever>}></Button>
                                    <Button size='sm' onClick={() => {
                                        navigate(`/app/monitoring/${item.id}`)
                                    }} startIcon={<Visibility></Visibility>}></Button>
                                </>

                            ) : <Typography variant='caption'>Preparing results...</Typography>}

                        </TableCell>
                    </TableRow>
                })}
            </TableBody>
        </Table>
        <Modal open={addModalOpen} onBackdropClick={() => { }} onClose={onFinishAddPolicy}>
            <AddPolicyModal onClose={onFinishAddPolicy}></AddPolicyModal>
        </Modal>

        <Modal open={editModalOpen} onBackdropClick={() => { }} onClose={onFinishEditPolicy}>
            <EditPolicyModal onClose={onFinishEditPolicy} {...editItem}></EditPolicyModal>
        </Modal>
    </Grid>


}