import React from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import { Link } from 'react-router-dom';
import LoginButton from './loginButton';
import LogoutButton from './logoutButton';
import SignupButton from './signupButton';

const AuthenticationButton = () => {
    const { isAuthenticated, isLoading, user } = useAuth0();

    return (isLoading) ? (
        <LinearProgress></LinearProgress>
    ) : (
        (isAuthenticated) ? (
            <Grid container>
                <Grid item xs={12}><Button variant='elevated' component={Link} to="app" >Go to App</Button></Grid>

                <Grid item xs={12}><LogoutButton /></Grid>
            </Grid>
        ) : (
            <Grid container>
                <Grid item xs={12} sx={{ m: 3 }}><LoginButton /></Grid>
                <Grid item xs={12} sx={{ m: 3 }}><SignupButton /></Grid>
            </Grid>
        )
    )

};

export default AuthenticationButton;