import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@mui/material';
import React from 'react';
import { events, logAnalyticsEvent } from '../services/firebase';

function LoginButton() {
  const { loginWithRedirect } = useAuth0();

  return (
    <Button
      variant="elevated"
      onClick={() => {
        logAnalyticsEvent(events.log_in);
        loginWithRedirect({
          redirectUri: `${window.location.origin}/app`,
        });
      }}
    >
      Log In
    </Button>
  );
}

export default LoginButton;
