import { Box, CircularProgress, Grid, Paper, Typography, Chip, Button, IconButton } from "@mui/material";
import * as React from 'react';
import moment from 'moment';
import { useEffect, useState } from "react";
import Moment from 'react-moment';
import { events, logAnalyticsEvent } from '../services/firebase';
import AppSlider from "./slider";
import { PlayCircle, StopCircle } from "@mui/icons-material";
import useInterval from "../hooks/interval";
import * as Turf from '@turf/turf';
import InsetMap from "./InsetMap";


export default function AppPlaybackControl(props) {
    const [activeDate, setActiveDate] = useState(moment().add(props.settings.application.mapFilter, 'h'));
    const [animating, setAnimating] = useState(false);
    const [animationInterval, setAnimationInterval] = useState(null);
    const [center, setCenter] = useState(null);


    const handleChange = (v) => {
        logAnalyticsEvent(events.use_playback_timeline, { value: v })
        const newSettings = {
            application: {
                mapFilter: v
            }
        }
        props.updateSettings(newSettings);
    };

    useEffect(() => {
        if (props.results && props.results.probabilities && props.results.probabilities.features.length) {
            const centroid = Turf.centroid(props.results.probabilities);
            setCenter(centroid.geometry.coordinates);
        }
    }, [props.results])


    useEffect(() => {
        let dt;

        if (!props.settings.simulation.started) {
            dt = moment().add(props.settings.application.mapFilter, 'h')
        } else {
            dt = moment(props.settings.simulation.started).add(props.settings.application.mapFilter, 'h')
        };
        setActiveDate(dt);
    }, [props.settings.simulation.started, props.settings.application.mapFilter]);


    const valueLabelFormat = (num) => {
        return moment(props.settings.simulation.started).add(num, 'h').format('ddd hh:mm A')
    }

    const animateFrame = () => {
        const v = props.settings.application.mapFilter;

        let nextV = v + 2;

        if (nextV > props.settings.simulation.duration) {
            nextV = 2;
        }
        const newSettings = {
            application: {
                mapFilter: nextV
            }
        }
        props.updateSettings(newSettings);
    }

    useInterval(animateFrame, animating ? 200 : null);

    const handleAnimation = () => {
        if (animating) {
            setAnimating(false);
        } else {
            setAnimating(true);
        }
    }

    return (props.results && props.results.perimeters.features && props.results.perimeters.features.length) ? (
        <Paper sx={{ position: 'absolute', bottom: 0, left: 0, zIndex: 120, p: 3, borderRadius: 4 }}>
            <Grid container>
                <Grid xs={12} sm={8}>
                    {(props.settings.simulation.simulation_name) ? <Typography variant='h4'>{props.settings.simulation.simulation_name}</Typography> : null}
                    {(props.settings.simulation.simulation_context) ? <Typography variant='subtitle1'>{props.settings.simulation.simulation_context}</Typography> : null}
                    {props.settings.simulation.started ? <Typography variant='subtitle1'>Forecast Time: <Moment format="MM/DD/YYYY hh:mm A">{props.settings.simulation.started}</Moment></Typography> : null}

                </Grid>
                <Grid xs={12} sm={4}>
                    {(center && center.length == 2) ? (
                        <div style={{ height: 90, width: 125 }}>
                            <InsetMap coordinates={center}></InsetMap>

                        </div>
                    ) : null}
                </Grid>

            </Grid>

            {props.settings.application.mapRendering ? (
                <>
                    <CircularProgress></CircularProgress>
                    <Typography>Setting up visualization...</Typography>
                </>

            ) : (
                <Box>
                    <Typography variant='h6' sx={{ mt: 1 }}>Growth Simulation <IconButton onClick={handleAnimation} color={(animating) ? "warning" : 'success'} component="label">{
                        (animating) ? <StopCircle /> : <PlayCircle></PlayCircle>
                    }</IconButton> </Typography>
                    <Typography><Moment date={activeDate} format="ddd MM/DD, hh:mm A" /></Typography>
                    <AppSlider valueLabelFormat={valueLabelFormat} discrete={true} py={0} onChange={handleChange} min={2} max={props.settings.simulation.duration} value={props.settings.application.mapFilter || 2} label='duration' step={2}></AppSlider>
                    <Grid container sx={{ mt: 1 }}>
                        <Grid item xs={12}>
                            <Typography variant='h6'>Legend</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant='body1'>48 Hour Growth Probability</Typography>

                            <Grid item xs={12}><Box height={12} width={12} sx={{ backgroundColor: '#fef0d9', display: 'inline-block' }}></Box><Typography variant='caption' sx={{ display: 'inline', paddingLeft: 1 }}>Low</Typography></Grid>
                            <Grid item xs={12}><Box height={12} width={12} sx={{ backgroundColor: '#fc8d59', display: 'inline-block' }}></Box><Typography variant='caption' sx={{ display: 'inline', paddingLeft: 1 }}>Medium</Typography></Grid>
                            <Grid item xs={12}><Box height={12} width={12} sx={{ backgroundColor: '#b30000', display: 'inline-block' }}></Box><Typography variant='caption' sx={{ display: 'inline', paddingLeft: 1 }}>High</Typography></Grid>

                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant='body1'>Structure Loss Potential</Typography>

                            <Grid item xs={12}><Box height={12} width={12} sx={{ backgroundColor: '#003f5c', display: 'inline-block' }}></Box><Typography variant='caption' sx={{ display: 'inline', paddingLeft: 1 }}>Monitoring</Typography></Grid>
                            <Grid item xs={12}><Box height={10} width={12} sx={{ backgroundColor: '#bc5090', display: 'inline-block' }}></Box><Typography variant='caption' sx={{ display: 'inline', paddingLeft: 1 }}>Possible</Typography></Grid>
                            <Grid item xs={12}><Box height={12} width={12} sx={{ backgroundColor: '#ffa600', display: 'inline-block' }}></Box><Typography variant='caption' sx={{ display: 'inline', paddingLeft: 1 }}>Threatened</Typography></Grid>

                        </Grid>
                    </Grid>

                </Box>
            )}

        </Paper >
    ) : null

}