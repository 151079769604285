import { Box, CssBaseline, Grid, Typography, Paper, Stack } from '@mui/material';
import * as Turf from '@turf/turf';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Line, LineChart, XAxis, YAxis, Tooltip } from 'recharts';
import * as React from 'react';


function getForecastColor(forecastProvider) {
    if (forecastProvider == 'gfs') {
        return '#8dd3c7';
    } else if (forecastProvider == 'nws') {
        return '#ffffb3'
    } else if (forecastProvider == 'tomorrowio') {
        return '#bebada'
    } else if (forecastProvider == 'openweather') {
        return '#fb8072'
    } else {
        return '#80b1d3'
    }
}

const CustomTooltip = ({ active, payload, label, units, mean }) => {
    if (active && payload && payload.length) {
        let val;
        if (mean) {
            val = payload.map((item) => {
                return item.value
            }).reduce((a, b) => a + b) / payload.length
        } else[
            val = payload[0].value
        ]

        return (
            <Paper>
                <Typography>{label} : {Math.round(val).toLocaleString()} {units}</Typography>
            </Paper>
        );
    }

    return null;
};

function filterDates(arr) {
    const start = moment().utc();
    const end = start.clone().add(48, 'hours');

    return arr.filter((d) => {
        const m1 = moment.unix(d.time / 1000).utc();
        return start.isSameOrBefore(m1) && end.isSameOrAfter(m1);
    })
}

function uniteForecastsForVariable(forecasts, variable) {
    const items = {}
    forecasts.forEach((forecast) => {
        forecast.forecast.forEach((item) => {
            const time = item.time;
            if (!items[time]) {
                const mapped = {
                    time: moment.unix(time / 1000),
                    timestamp: moment.unix(time / 1000).local().format("ddd, hA")
                }
                items[time] = mapped;
            }
            items[time][forecast.provider] = item[variable].toFixed(2)
        })
    });
    return filterDates(Object.values(items)).sort((a, b) => { return a.time - b.time });
}

export default function StatsView(props) {
    const [areaArr, setAreaArr] = useState([]);
    const [realIds, setRealIds] = useState([]);
    const [humidityArr, setHumidityArr] = useState([]);
    const [tempArr, setTempArr] = useState([]);
    const [windArr, setWindArr] = useState([]);
    const [ccArr, setCCArr] = useState([]);
    const [colorMap, setColorMap] = useState({});

    useEffect(() => {
        if (!props.results.forecast) {
            return;
        }
        setHumidityArr(uniteForecastsForVariable(props.results.forecast, 'relative_humidity'));
        setTempArr(uniteForecastsForVariable(props.results.forecast, 'air_temperature'));
        setCCArr(uniteForecastsForVariable(props.results.forecast, 'cloud_cover'));
        setWindArr(uniteForecastsForVariable(props.results.forecast, 'wind_speed_max'));
    }, [props.results.forecast]);

    useEffect(() => {
        const res = {}
        const resIds = []
        const colorLookup = {};
        props.results.perimeters.features.forEach((f) => {
            const id = f.properties.realization_id;
            if (resIds.indexOf(id) == -1) {
                resIds.push(id);
            };
            colorLookup[f.properties.realization_id] = f.properties._style_color.toLowerCase();
            const time = f.properties.elapsed_minutes;
            if (!res[time]) {
                res[time] = { 'timestamp': moment(f.properties.current_datetime).local().format("ddd, hA") };
            };
            res[time][id] = Turf.area(f) / 4047
        });
        const arr = Object.values(res)
        setRealIds(resIds);
        setAreaArr(arr);
        setColorMap(colorLookup);
    }, [props.results.perimeters]);

    return (

        (props.results && props.results.perimeters.features && props.results.perimeters.features.length) ? (
            <Box sx={{ p: 2, overflow: 'auto' }} style={{ 'overflow': 'auto', maxHeight: '100vh', color: 'white' }}>
                <Stack spacing={2}>
                    <Typography variant='h6'>{"Fire Growth"}</Typography>
                    <LineChart
                        width={200}
                        height={200}
                        data={areaArr}
                        margin={{
                            top: 5,
                            right: 5,
                            left: 0,
                            bottom: 0,
                        }}
                    >
                        <YAxis tick={{ fill: 'white' }} tickFormatter={(d) => {
                            return Math.round(d).toLocaleString()
                        }} />
                        <XAxis dataKey={'timestamp'} tick={{ fill: 'white' }} />
                        {realIds.map((d, i) => {
                            return <Line key={d} name={`Simulation ${i}`} type="basis" dataKey={d} stroke={colorMap[d]} dot={false} strokeWidth={2} />
                        })}
                        <Tooltip content={<CustomTooltip units='acres' />} />
                    </LineChart>
                    <Typography variant='h6'>{"Relative Humidity (%)"}</Typography>
                    <LineChart
                        width={200}
                        height={200}
                        data={humidityArr}
                        margin={{
                            top: 5,
                            right: 5,
                            left: 0,
                            bottom: 0,
                        }}
                    >
                        <YAxis tick={{ fill: 'white' }} />
                        <XAxis dataKey={'timestamp'} tick={{ fill: 'white' }} />
                        {props.results.forecast.map((d) => {
                            return <Line key={d.provider} type="basis" dataKey={d.provider} stroke={getForecastColor(d.provider)} dot={false} strokeWidth={2} />
                        })}
                        <Tooltip content={<CustomTooltip units='%' />} />
                    </LineChart>

                    <Typography variant='h6'>{"Air Temperature (F)"}</Typography>
                    <LineChart
                        width={200}
                        height={200}
                        data={tempArr}
                        margin={{
                            top: 5,
                            right: 5,
                            left: 0,
                            bottom: 0,
                        }}
                    >
                        <YAxis tick={{ fill: 'white' }} />
                        <XAxis dataKey={'timestamp'} tick={{ fill: 'white' }} />
                        {props.results.forecast.map((d) => {
                            return <Line key={d.provider} type="basis" dataKey={d.provider} stroke={getForecastColor(d.provider)} dot={false} strokeWidth={2} />
                        })}
                        <Tooltip content={<CustomTooltip units='%' />} />
                    </LineChart>


                    <Typography variant='h6'>{"Wind Speed (mph)"}</Typography>
                    <LineChart
                        width={200}
                        height={200}
                        data={windArr}
                        margin={{
                            top: 5,
                            right: 5,
                            left: 0,
                            bottom: 0,
                        }}
                    >
                        <YAxis tick={{ fill: 'white' }} />
                        <XAxis dataKey={'timestamp'} tick={{ fill: 'white' }} />
                        {props.results.forecast.map((d) => {
                            return <Line key={d.provider} type="basis" dataKey={d.provider} stroke={getForecastColor(d.provider)} dot={false} strokeWidth={2} />
                        })}
                        <Tooltip content={<CustomTooltip units='mph' />} />
                    </LineChart>


                    <Typography variant='h6'>{"Cloud Cover (%)"}</Typography>
                    <LineChart
                        width={200}
                        height={200}
                        data={ccArr}
                        margin={{
                            top: 5,
                            right: 5,
                            left: 0,
                            bottom: 0,
                        }}
                    >
                        <YAxis tick={{ fill: 'white' }} type="number" domain={[0, 100]} />
                        <XAxis dataKey={'timestamp'} tick={{ fill: 'white' }} />
                        {props.results.forecast.map((d) => {
                            return <Line key={d.provider} type="basis" dataKey={d.provider} stroke={getForecastColor(d.provider)} dot={false} strokeWidth={2} />
                        })}
                        <Tooltip content={<CustomTooltip units='%' />} />
                    </LineChart>
                </Stack>
            </Box>
        ) : (
            <Grid container>
                <CssBaseline></CssBaseline >
                <Typography variant='h6' sx={{ p: 3 }}>After you run a simulation, statistics about it will be available here.</Typography>
            </Grid >
        )
    )
}