import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { useEffect, useState } from 'react';

import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { Outlet } from 'react-router-dom';
import AppSnackbar from './components/snackbar';
import ResultsContext from './context/Results';
import SettingsContext from './context/Settings';
import defaultResults from './defaults/results';
import defaultSettings from './defaults/settings';
import runSimulation from './services/simulation';
import { mergeDeep, hasRole } from './utils';

import { ThemeProvider } from '@mui/material/styles';
import moment from 'moment';
import { events, logAnalyticsEvent } from './services/firebase';
import darkTheme from './theme';
import * as React from 'react';



export function RawLayout() {

    const { getAccessTokenSilently, user } = useAuth0();

    const [settings, setSettings] = useState(defaultSettings);
    const [results, setResults] = useState(defaultResults);
    const [snackbarOpen, setSnackbarOpen] = useState(true);
    const [structures, setStructures] = useState(null);


    const updateSettings = (newSettings) => {
        setSettings(mergeDeep({}, settings, newSettings))
    }

    const updateResults = (res) => {
        setResults(mergeDeep({}, results, res));
    }

    const resetSettings = () => {
        setSettings(defaultSettings)
        setResults(defaultResults);
        setStructures({ 'type': "FeatureCollection", "features": [] });
    }

    useEffect(() => {
        const newSettings = {
            application: {
                user: user.email
            }
        };
        updateSettings(newSettings);
    }, [user]);




    useEffect(async () => {
        if (settings.simulation.running) {
            try {
                updateResults(defaultResults);
                const result = await runSimulation(settings, user.email);
                const elapsed = Date.now() - Date.parse(settings.simulation.started);
                const duration = moment.utc(elapsed).format('HH:mm:ss')
                const message = `Simulation completed in ${duration} `
                const newSettings = {
                    simulation: {
                        running: false
                    },
                    application: {
                        snackbar: {
                            text: message,
                            style: "success"
                        },
                        mapFilter: 2
                    }
                }
                updateSettings(newSettings);
                updateResults(result);
                setSnackbarOpen(true);
                setStructures(result['structures']);
                logAnalyticsEvent(events.run_simulation_complete)

            } catch (err) {
                console.error(err);
                const elapsed = Date.now() - Date.parse(settings.simulation.started);

                const duration = moment.utc(elapsed).format('HH:mm:ss')
                const message = `Simulation failed after ${duration}ms`

                const newSettings = {
                    simulation: {
                        running: false
                    },
                    application: {
                        snackbar: {
                            text: message,
                            style: "error"
                        }
                    }
                }
                updateSettings(newSettings);
                setSnackbarOpen(true);
                logAnalyticsEvent(events.run_simulation_complete)

            }
        }
    }, [settings.simulation.running])

    const layout = (
        <Box sx={{ display: 'flex' }}>
            <ThemeProvider theme={darkTheme}>
                <SettingsContext.Provider value={{ settings, updateSettings, resetSettings }}>
                    <ResultsContext.Provider value={{ results, updateResults, structures }}>
                        <CssBaseline></CssBaseline>
                        <Box
                            component="main"
                            sx={{ flexGrow: 1, bgcolor: 'background.default' }}
                        >
                            <Outlet />
                        </Box>
                        <AppSnackbar message={settings.application.snackbar.text} severity={settings.application.snackbar.style} open={snackbarOpen} onClose={() => { setSnackbarOpen(false) }}></AppSnackbar>
                    </ResultsContext.Provider>
                </SettingsContext.Provider>
            </ThemeProvider>
        </Box >
    )
    return layout;
}


export default function PrimaryLayout() {
    const ProtectedLayout = withAuthenticationRequired(RawLayout);
    return <ProtectedLayout></ProtectedLayout>
}