import { useAuth0 } from '@auth0/auth0-react';
import { FileCopy } from "@mui/icons-material";
import { Box, Button, TextField, Typography, Slider, Stack, Tooltip, FormControlLabel, Switch } from "@mui/material";
import { useState } from 'react';
import { events, logAnalyticsEvent } from './services/firebase';
import * as React from 'react';

const MONITORING_HOST = `https://api-core-production-63vbfoxayq-uw.a.run.app`
// const MONITORING_HOST = 'http://localhost:8000';
// 
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: 5,
};

export default function AddPolicyModal(props) {

    const { user } = useAuth0();


    const [file, setFile] = useState(false);
    const [name, setName] = useState("");
    const [isUploading, setIsUploading] = useState(false);
    const [isComplete, setIsComplete] = useState(false);
    const [distanceThreshold, setDistanceThreshold] = useState(250);
    const [valueThreshold, setValueThreshold] = useState(100);
    const [countThreshold, setCountThreshold] = useState(0);
    const [alerting, setAlerting] = useState(true);
    const [fresh, setFresh] = useState(true);

    const handleFileChange = (e) => {
        setIsUploading(true);
        const file = e.target.files[0]; // accesing file
        setFile(file); // storing file
    }

    const uploadFile = async () => {
        const formData = new FormData();
        formData.append('file', file); // appending file
        formData.append('policy_name', name)
        formData.append("distance_threshold", distanceThreshold);
        formData.append('count_threshold', countThreshold);
        formData.append("value_threshold", valueThreshold);
        const baseUrl = `${MONITORING_HOST}/policies/${user.email}`
        try {
            const req = await fetch(baseUrl, { body: formData, method: 'POST' });
            const res = await req.json();
            if (req.status > 204) {
                throw new Error("Unexpected status code");
            }
            logAnalyticsEvent(events.upload_policy, { user: user.email });
            props.onClose()
        } catch (err) {
            console.error(err);
            logAnalyticsEvent(events.upload_policy_error, { user: user, error: err });
            alert("There was an error uploading your file");
        } finally {
            setIsUploading(false);
        }
    }

    return (
        <Box sx={style}>
            <Typography>Create New Policy</Typography>
            <TextField error={(!fresh && !name)} helperText={(!fresh && !name) ? "Name cannot be empty" : ""} placeholder={"New Policy Name"} fullWidth onChange={(e) => { 
                setFresh(false)
                setName(e.target.value) }} sx={{ my: 3 }}></TextField>

            <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                <Tooltip title="Maximum distance from forecast fire front to trigger email alerts">
                    <Typography>Distance Threshold: {distanceThreshold} meters</Typography>
                </Tooltip>
                <Slider valueLabelDisplay="auto" onChange={(e) => { setDistanceThreshold(e.target.value) }} value={distanceThreshold} step={10} max={2000}></Slider>
            </Stack>

            <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                <Tooltip title="Minimum threatened value to trigger email alerts">
                    <Typography>Value Threshold: ${valueThreshold.toLocaleString()}</Typography>
                </Tooltip>

                <Slider valueLabelDisplay="auto" onChange={(e) => { setValueThreshold(e.target.value) }} value={valueThreshold} step={1000} max={1000000}></Slider>
            </Stack>

            <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                <Tooltip title="Minimum number of threatened properties to trigger email alerts">
                    <Typography>Count Threshold: {countThreshold}</Typography>
                </Tooltip>
                <Slider valueLabelDisplay="auto" onChange={(e) => { setCountThreshold(e.target.value) }} value={countThreshold} step={1}  max={100}></Slider>
            </Stack>


            <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                <FormControlLabel control={<Switch color='primary' checked={alerting} onChange={() => { setAlerting(!alerting) }} />} label="Email Alerting Enabled" />

            </Stack>

            <Button variant='contained' color='success' component="label" startIcon={<FileCopy />} >{(file) ? file.name : "Add Monitoring Locations"}
                <input onChange={handleFileChange} hidden accept="text/csv" type="file" />
            </Button>

            <Button color='secondary' sx={{ mt: 2 }} onClick={props.onClose}>Cancel</Button>
            {file && name ? <Button color='success' variant='contained' onClick={uploadFile}>Done</Button> : null}
        </Box>
    )
}