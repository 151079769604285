import Slider from '@mui/material/Slider';
import * as React from 'react';


export default function AppSlider(props) {
    return (
        <Slider
        sx={{py: props.py ? props.py : 0}}
            value={props.value}
            valueLabelDisplay="auto"
            step={props.step || 1}
            marks={props.discrete}
            min={props.min}
            max={props.max}
            valueLabelFormat={props.valueLabelFormat}
            onChange={(e) => { return props.onChange(e.target.value) }}
        />
    );
}