import { createTheme, ThemeProvider } from '@mui/material/styles';

const themeOptions = {

    palette: {
        type: 'dark',
        primary: {
          main: '#fdfdfd',
        },
        secondary: {
          main: '#a8e8ff',
        },
        background: {
          default: '#212121',
          paper: '#424242',
        },
        text: {
          primary: '#ffffff',
        },
      },
      typography: {
        fontSize: 10,
        fontFamily: 'Open Sans',
      },
};

const darkTheme = createTheme(themeOptions);

export default darkTheme;