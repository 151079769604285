import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import * as React from 'react';

function timeBlock(hrs, probability) {
    if (probability == 0 && hrs == 0) {
        return "48+ Hours";
    } else if (hrs < 8) {
        return "< 8 Hours"
    } else if (hrs < 8) {
        return "8 - 16 Hours"
    } else if (hrs < 24) {
        return "16 - 24 Hours";
    } else if (hrs < 32) {
        return "24 - 32 Hours";
    } else if (hrs < 48) {
        return "32 - 48 Hours";
    } else {
        return "48+ Hours"
    }
}

export default function StructureListView(props) {
    return <Table sx={{ maxHeight: '100vh', overflowY: 'auto' }}>
        <TableHead>
            <TableRow>
                <TableCell>Address</TableCell>
                <TableCell>Probability</TableCell>
                <TableCell>Time of Arrival</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {(props.structures && props.structures.features && props.structures.features.length) ? props.structures.features.map((structure) => {
                return <TableRow key={structure.id}>
                    <TableCell>{structure.properties.number} {structure.properties.street}</TableCell>
                    <TableCell>{(structure.properties.probability * 100).toFixed(1)} %</TableCell>
                    <TableCell>{timeBlock(structure.properties.eta_min / 60, structure.properties.probability)}</TableCell>
                </TableRow>
            }) : <TableRow>
                <TableCell>No Structures Impacted in the Forecast Period</TableCell>
            </TableRow>}
        </TableBody>
    </Table>
}