import * as Turf from '@turf/turf';
import moment from 'moment';
import { assignColors } from './../utils';

// const SIMULATION_HOST = "https://api.willow-labs.co"
// const SIMULATION_HOST = "http://localhost:8000"
// const SIMULATION_HOST = "https://apicore-staging-dot-fire3-305600.wl.r.appspot.com"
const SIMULATION_HOST = "https://api-core-production-63vbfoxayq-uw.a.run.app"

const ACCESS_TOKEN = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjoid2lsbG93LXN0dWRpby1kZW1vIiwiZXhwIjoxNjgwODA1MTU4LjB9.eKr3GWdpO01oGBgDou3hXMXpoUiGdwUkaz2lLJdYgdY";

async function loadMonitoringProperties(userID, bbox) {
    const MONITORING_HOST = `https://api-core-production-63vbfoxayq-uw.a.run.app`
    const req = await fetch(`${MONITORING_HOST}/policies/${userID}/impacted?bbox=${bbox.join(",")}`);
    const res = await req.json();
    return res;
}


const getFuelMoisture = async (x, y, time) => {
    time = moment().format('YYYYMMDDhhmm'); //time.substring(16).split(":").join("").split("T").join("").split("-").join("").split(" ").join("");

    const url = `https://api.synopticdata.com/v2/stations/nearesttime?&token=1091402d40c64b979339035359ede479&attime=${time}&within=120&units=english&output=json&vars=fuel_moisture&radius=${y},${x},20`;
    const res = await fetch(url);
    const data = await res.json();
    if (!data.SUMMARY.NUMBER_OF_OBJECTS) {
        return 8
    }
    const results = data.STATION.map((d) => {
        return +d.OBSERVATIONS.fuel_moisture_value_1.value;
    });

    const avgValue = results.reduce((a, b) => {
        return a + b
    }, 0) / results.length;
    return avgValue;
}

async function awaitJSON(url) {
    const req = await fetch(url);
    const res = await req.json();
    return res
}


export default async function runSimulation(settings, userID) {
    const token = ACCESS_TOKEN;

    const customWeather = {
        wind_speed: (!settings.simulation.useForecast) ? settings.simulation.weather.windSpeed : null,
        wind_direction: (!settings.simulation.useForecast) ? settings.simulation.weather.windDirection : null,
        relative_humidity: (!settings.simulation.useForecast) ? settings.simulation.weather.relativeHumidity : null,
        temperature: (!settings.simulation.useForecast) ? settings.simulation.weather.temperature : null,
    }

    const now = moment().subtract(1, 'minute').format()
    const center = Turf.centroid(settings.simulation.ignition.geometry).geometry.coordinates;
    const fuelMoisture = await getFuelMoisture(center[0], center[1], now);


    const payload = {
        ignition: settings.simulation.ignition,
        simulation_config: {
            start_at: now,
            use_forecast: settings.simulation.useForecast,
            duration: settings.simulation.duration,
            iteration_count: settings.simulation.count,
            custom_weather: (!settings.simulation.useForecast) ? customWeather : undefined,
            iteration_count: settings.simulation.count,
            forecast_provider: (settings.simulation.forecast_provider == 'all') ? ['nws', 'openweather'] : settings.simulation.forecast_provider,
            fuel_moisture: {
                dead: fuelMoisture,
                live: settings.simulation.liveFuelMoisture
            },
            advanced_config: {
                spot_probability: settings.simulation.spotProbability / 100,
                distance_resolution: settings.simulation.resolution.distance,
                perimeter_resolution: settings.simulation.resolution.perimeter,
                timestep: settings.simulation.timestep,
                landscape_resolution: settings.simulation.resolution.landscape,
                landscape_structure_probability: 0.5,
                landscape_structure_buffer: 15,
                landscape_structure_fill: 203
            }
        },
        treatment: (settings.simulation.useTreatment) ? settings.simulation.treatment : null
    }

    const url = `${SIMULATION_HOST}/v1/simulation?access_token=${settings.application.accessToken}`;

    const res = await fetch(url, { method: "POST", body: JSON.stringify(payload), headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` } })
    const data = await res.json();
    const promises = [
        awaitJSON(data.data.structures),
        awaitJSON(data.data.probabilities),
        awaitJSON(data.data.perimeters),
        awaitJSON(data.data.forecast),
    ]

    const results = await Promise.all(promises);

    const probs = results[1];
    const bbox = Turf.bbox(probs);
    const props = await loadMonitoringProperties(userID, [bbox[0]-0.005, bbox[1]-0.005, bbox[2]+0.005, bbox[3]+0.005])

    return {
        perimeters: assignColors(results[2]),
        probabilities: results[1],
        structures: results[0],
        forecast: results[3],
        ignition: data,
        impactedProperties: props,
        urls: {
            perimeter_file_url: data.data.perimeters,
            structure_file_url: data.data.structures,
            probability_file_url: data.data.probabilities
        }
    }
}