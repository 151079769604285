import Box from '@mui/material/Box';

import { MapOutlined } from '@mui/icons-material';
import DownloadIcon from '@mui/icons-material/Download';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import { Tooltip } from '@mui/material';
import Tab from '@mui/material/Tab';
import DoorbellIcon from '@mui/icons-material/Doorbell';

import Tabs from '@mui/material/Tabs';
import * as React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import ResultsContext from './context/Results';
import SettingsContext from './context/Settings';
import DashboardMapWithTools from './DashboardMap';
import ExportView from './ExportView';
import StatsView from './StatsView';
import StructureListView from './StructureListView';
import PropertyListView from './PropertyListView';
import { useAuth0 } from '@auth0/auth0-react';
import { hasRole } from './utils';

export default function DashboardLayout(props) {
    const [value, setValue] = React.useState(0);
    const { pathname } = useLocation();
    const params = useParams();
    const { user } = useAuth0();
    const hasMonitoringRole = hasRole(user, 'MonitoringRole')

    React.useEffect(() => {
        if (params.tabId === "map") {
            setValue(0);
        } else if (params.tabId === "statistics") {
            setValue(1);
        }
        else if (params.tabId === "structures") {
            setValue(2);
        }
        else if (params.tabId === "assets") {
            setValue(3);
        }

        else if (params.tabId === "export") {
            setValue(4);
        }
    }, [pathname])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    function a11yProps(index) {
        return {
            id: `vertical-tab-${index}`,
            'aria-controls': `vertical-tabpanel-${index}`,
        };
    }

    return (
        <Box style={{ width: '100%' }}>
            <ResultsContext.Consumer>
                {({ results, updateResults, structures }) => (
                    <SettingsContext.Consumer>
                        {({ settings, updateSettings }) => (
                            <Box sx={{ display: 'flex' }}>
                                <Box sx={{ flexGrow: 2 }}>
                                    <Box sx={{ display: 'flex' }}>
                                        <Box sx={{ flexGrow: 1 }}>
                                            <DashboardMapWithTools showStructures={(value !== 3)} showAssets={(value == 3)} settings={settings} structures={structures} updateSettings={updateSettings} results={results} updateResults={updateResults}></DashboardMapWithTools>
                                        </Box>
                                        {(value == 1) ? (
                                            <Box sx={{ width: 256, minWidth: 256, overflowY: 'auto', maxHeight: '100vh', height: '100vh', position: 'absolute', zIndex: 14, top: 0, right: 36, backgroundColor: 'background.paper' }} alignContent='start'>
                                                <StatsView settings={settings} structures={structures} updateSettings={updateSettings} results={results} updateResults={updateResults}></StatsView>
                                            </Box>) : null
                                        }
                                        {(value == 2) ? (
                                            <Box sx={{ width: 256, overflowY: 'auto', maxHeight: '100vh', height: '100vh', position: 'absolute', zIndex: 14, top: 0, right: 36, backgroundColor: 'background.paper' }} alignContent='start'>
                                                <StructureListView settings={settings} structures={structures} updateSettings={updateSettings} results={results} updateResults={updateResults}></StructureListView>
                                            </Box>) : null
                                        }
                                        {(value == 3) ? (
                                            <Box sx={{ width: 256, overflowY: 'auto', maxHeight: '100vh', height: '100vh', position: 'absolute', zIndex: 14, top: 0, right: 36, backgroundColor: 'background.paper' }} alignContent='start'>
                                                <PropertyListView results={results}></PropertyListView>
                                            </Box>) : null
                                        }
                                        {(value == 4) ? (
                                            < Box sx={{ width: 256, minWidth: 256, maxHeight: '100vh', height: '100vh', position: 'absolute', zIndex: 14, top: 0, right: 36, backgroundColor: 'background.paper' }} alignContent='start'>
                                                <ExportView settings={settings} structures={structures} updateSettings={updateSettings} results={results} updateResults={updateResults}></ExportView>
                                            </Box>) : null
                                        }

                                    </Box>
                                </Box>
                                <Box >
                                    {
                                        (results.perimeters && results.perimeters.features.length) ? (
                                            <Tabs textColor="secondary"
                                                orientation="vertical"
                                                variant="scrollable"
                                                value={value}
                                                onChange={handleChange}
                                                sx={{ p: 0, width: 36 }}
                                            >
                                                <Tab sx={{ p: 0, minWidth: 36 }} icon={<Tooltip title='Map View'><MapOutlined style={{
                                                    color: 'white', display: "flex",
                                                    alignItems: "center",
                                                }} /></Tooltip>} {...a11yProps(0)} />
                                                <Tab sx={{ p: 0, minWidth: 36 }} icon={<Tooltip title='Statistics'><ShowChartIcon style={{
                                                    color: 'white', display: "flex",
                                                    alignItems: "center",
                                                }} /></Tooltip>} {...a11yProps(1)} />
                                                <Tab sx={{ p: 0, minWidth: 36 }} icon={<Tooltip title='Structures'><LocationCityIcon style={{
                                                    color: 'white', display: "flex",
                                                    alignItems: "center",
                                                }} /></Tooltip>} {...a11yProps(2)} />

                                                    <Tab sx={{ p: 0, minWidth: 36 }} icon={<Tooltip title='Assets'><DoorbellIcon style={{
                                                        color: 'white', display: "flex",
                                                        alignItems: "center",
                                                    }} /></Tooltip>} {...a11yProps(1)} />


                                                <Tab sx={{ p: 0, minWidth: 36 }} icon={<Tooltip title='Export'><DownloadIcon style={{
                                                    color: 'white', display: "flex",
                                                    alignItems: "center",
                                                }} /></Tooltip>} {...a11yProps(3)} />
                                            </Tabs>
                                        ) : null
                                    }

                                </Box>
                            </Box>
                        )}

                    </SettingsContext.Consumer>)}
            </ResultsContext.Consumer>
        </Box >
    );
}
