import { Box, Button, Card, CardContent, Chip, Divider, Drawer, Grid, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader } from "@mui/material";

import React from "react";
import Logo from './Logo';

import { useAuth0 } from "@auth0/auth0-react";
import { Map, QuestionAnswer } from "@mui/icons-material";
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import SettingsInputAntennaIcon from '@mui/icons-material/SettingsInputAntenna';
import ReviewsIcon from '@mui/icons-material/Reviews';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useNavigate } from "react-router-dom";
import LogoutButton from "./components/logoutButton";
import { events, logAnalyticsEvent } from "./services/firebase";
import { hasRole } from './utils';

const drawerWidth = 240;



export default function HomeView() {
	const navigate = useNavigate();
	const { user } = useAuth0();
	const roles = user['willow-studio/roles'];

	return (
		<Grid container>
			<Drawer
				sx={{
					width: drawerWidth,
					flexShrink: 0,
					'& .MuiDrawer-paper': {
						width: drawerWidth,
						boxSizing: 'border-box',
					},
					color: 'white'
				}}
				style={{ color: 'white' }}
				variant="permanent"
				anchor="left">
				<Toolbar>
				</Toolbar>
				<Divider />
				<ListSubheader color='primary'>Actions</ListSubheader>
				<ListItem key={'run-simulation'} disablePadding onClick={() => { navigate("/app/dashboard/map") }}>
					<ListItemButton>
						<ListItemIcon style={{ 'color': 'white' }}>
							<SettingsInputAntennaIcon></SettingsInputAntennaIcon>
						</ListItemIcon>
						<ListItemText style={{ 'color': 'white' }} primary={"Run Simulation"}
							primaryTypographyProps={{ color: 'white', fontSize: 18 }}
							secondaryTypographyProps={{ color: 'white', fontSize: 12 }}
							secondary={"Simulate a wildfire using a custom ignition point."} />
					</ListItemButton>
				</ListItem>
				<ListItem key={'incidents'} disablePadding onClick={() => { navigate("/app/incidents") }}>
					<ListItemButton>
						<ListItemIcon style={{ 'color': 'white' }}>
							<Map></Map>
						</ListItemIcon>
						<ListItemText
							primaryTypographyProps={{ color: 'white', fontSize: 18 }}
							secondaryTypographyProps={{ color: 'white', fontSize: 12 }}

							style={{ 'color': 'white' }} primary={"Major Wildfires"} secondary={"Review forecasts for current large wildfires"} />
					</ListItemButton>
				</ListItem>
				{hasRole(user, 'MonitoringUser') ? (
					<ListItem key={'monitoring'} disablePadding onClick={() => { navigate("/app/monitoring") }}>
						<ListItemButton>
							<ListItemIcon style={{ 'color': 'white' }}>
								<NotificationsActiveIcon></NotificationsActiveIcon>
							</ListItemIcon>
							<ListItemText
								primaryTypographyProps={{ color: 'white', fontSize: 18 }}
								secondaryTypographyProps={{ color: 'white', fontSize: 12 }}

								style={{ 'color': 'white' }} primary={"Monitoring"} secondary={"Monitor properties and get alerts if they are within a wildfire trajectory"} />
						</ListItemButton>
					</ListItem>
				) : null}
				<Divider></Divider>
				{hasRole(user, 'Admin') ? (
					<>
					<ListSubheader>
					<ListSubheader color='primary'>Admin</ListSubheader>
					</ListSubheader>
				
					<ListItem key={'accuracy'} disablePadding onClick={() => { navigate("/app/groundtruth") }}>
						<ListItemButton>
							<ListItemIcon style={{ 'color': 'white' }}>
								<ReviewsIcon></ReviewsIcon>
							</ListItemIcon>
							<ListItemText
								primaryTypographyProps={{ color: 'white', fontSize: 18 }}
								secondaryTypographyProps={{ color: 'white', fontSize: 12 }}

								style={{ 'color': 'white' }} primary={"Groundtruth"} secondary={"Assess model performance and accuracy"} />
						</ListItemButton>
					</ListItem>

					<ListItem key={'tokens'} disablePadding onClick={() => { navigate("/app/tokens") }}>
						<ListItemButton>
							<ListItemIcon style={{ 'color': 'white' }}>
								<ReviewsIcon></ReviewsIcon>
							</ListItemIcon>
							<ListItemText
								primaryTypographyProps={{ color: 'white', fontSize: 18 }}
								secondaryTypographyProps={{ color: 'white', fontSize: 12 }}

								style={{ 'color': 'white' }} primary={"Tokens"} secondary={"Manage user tokens"} />
						</ListItemButton>
					</ListItem>
					</>
				) : null}
				
			</Drawer>
			<Box sx={{ p: 3 }} style={{ marginLeft: drawerWidth, marginRight: 64 }}>
				<Grid container>
					<Grid item xs={12}>
						<Logo></Logo>
					</Grid>
					<Grid item xs={12} sx={{ mt: 2 }}>
						{(user.given_name) ?
							(
								<Typography variant='h2'>Hi {user.given_name},</Typography>
							) : null
						}
						<Typography variant='h6'>Welcome to Willow Studio</Typography>
					</Grid>
					<Grid item xs={8} sx={{ mt: 3 }}>
						<Typography>With Willow Studio, you can view near-term forecasts for current major wildfires or simulate a custom wildfire event.
							Willow's wildfire forecasts are based on highly-accurate wildfire growth modeling simulations and hyperlocal weather predictions. </Typography>
					</Grid>
					<Grid item xs={12} sx={{ mt: 3 }}>
						<Button onClick={() => { logAnalyticsEvent(events.request_support) }} color='success' variant='contained' startIcon={<QuestionAnswer></QuestionAnswer>}><a href="mailto:hello@willow-labs.co">Get Support</a></Button>
						<LogoutButton></LogoutButton>
					</Grid>
				</Grid>
			</Box>
		</Grid>
	)
}