import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';

export default function LogoutPage() {
    const [errorState, setErrorState] = React.useState(null);
    const { logout } = useAuth0();
    React.useEffect(() => {
        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });
        setErrorState(params.error_description);
    }, []);

    React.useEffect(() => {
        logout({
            returnTo: window.location.origin + `?error_description=${errorState}`,
        })
    }, []);

    return <p>Logging you out...</p>
}