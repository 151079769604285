import { useAuth0 } from '@auth0/auth0-react';
import {
    Box, CssBaseline, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography
} from '@mui/material';
import JSONDownloadButton from './components/jsonDownloadButton';
import { events, logAnalyticsEvent } from './services/firebase';
import * as React from 'react';


export default function ExportView(props) {

    const { getAccessTokenSilently, user } = useAuth0();

    const logStructureDownload = () => {
        logAnalyticsEvent(events.download_structures, { user: user });
    }

    const logPerimeterDownload = () => {
        logAnalyticsEvent(events.download_structures, { user: user });
    }


    const perimeterCollection = { type: "FeatureCollection", features: props.results.perimeters.features };
    const structureCollection = { type: "FeatureCollection", features: props.results.structures.features };

    return (
        (props.results && props.results.urls && props.results.perimeters && props.results.perimeters.features.length) ? (
            <Box sx={{ width: 200, p: 3 }}>
                <Typography variant='h6'>Download</Typography>
                <Table style={{ fontSize: 'small', width: '100%', maxWidth: '100%' }}>
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>GeoJSON</TableCell>
                        </TableRow>
                    </TableHead>

                    {props.results ? (
                        <TableBody>
                            <TableRow>
                                <TableCell>Perimeters</TableCell>
                                <TableCell><JSONDownloadButton url={props.results.urls.perimeter_file_url} filename={'perimeters.geojson'}></JSONDownloadButton></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Structures</TableCell>
                                <TableCell><JSONDownloadButton url={props.results.urls.structure_file_url} filename={'structures.geojson'}></JSONDownloadButton></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Probabilities</TableCell>
                                <TableCell><JSONDownloadButton url={props.results.urls.probability_file_url} filename={'probabilities.geojson'}></JSONDownloadButton></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Assets</TableCell>
                                <TableCell><JSONDownloadButton content={props.results.impactedProperties} filename={'assets.geojson'}></JSONDownloadButton></TableCell>
                            </TableRow>
                        </TableBody>
                    ) : (
                        <TableBody>
                            <TableRow>
                                <TableCell>Simulation results are available here</TableCell>
                            </TableRow>
                        </TableBody>
                    )}

                </Table>
            </Box>
        ) : (
            <Grid container>
                <CssBaseline></CssBaseline>
                <Typography variant='h6' sx={{ p: 3 }}>After you run a simulation, results will be available here.</Typography>
            </Grid>
        )
    )
}