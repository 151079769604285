import React from 'react';
import { ComposableMap, Geographies, Geography, Marker } from "react-simple-maps";


export default function InsetMap(props) {
    return (
        <div>
            <ComposableMap
                projection="geoAlbersUsa"
            >
                <Geographies geography="/states.json">
                    {({ geographies }) =>
                        geographies.filter((d) => {
                            return d.properties.NAME != 'Alaska'
                        }).map((geo) => (
                            <Geography key={geo.rsmKey} geography={geo} />
                        ))
                    }
                </Geographies>
                <Marker key={'marker'} coordinates={props.coordinates ? props.coordinates : [-122, 37]}>
                    <circle r={10} fill="#F00" stroke="#fff" strokeWidth={2} />
                </Marker>
            </ComposableMap>
        </div>
    )
}