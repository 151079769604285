const colorInterp = [
    'interpolate', ['linear'],
    ['to-number', ['get', 'probability']],
    0,
    '#003f5c',
    0.5,
    '#bc5090',
    1,
    '#ffa600'
]


const layerProps = {
    incidents: {
        "type": "fill", "paint": {
            "fill-color": 'orange',
            "fill-opacity": 0.5,
        }
    },
    perimeters: {
        "type": "line", "paint": {
            "line-color": ['get', '_style_color'],
            "line-opacity": 0.9,
            "line-width": 2
        },
        // 'layout': {
        //     'line-sort-key': ['get', 'realization_id']
        // }
    },
    probabilities: {
        type: 'fill', paint: {
            'fill-color': ['interpolate', ['linear'],
                ['to-number', ['get', 'value']],
                0,
                '#fef0d9',
                0.25,
                '#ffffb2',
                0.5,
                '#fecc5c',
                0.75,
                '#fd8d3c',
                1,
                '#f03b20'
            ],
            'fill-opacity': [
                'interpolate',
                ['linear'],
                ['to-number', ['get', 'value']],
                0,
                0,
                0.01,
                0.375
            ]
        }
    },
    structures: {
        type: 'circle',
        paint: {
            'circle-radius': [
                'interpolate',
                ['linear'],
                ['zoom'],
                12,
                3,
                18,
                9
            ],
            'circle-color': colorInterp,
            'circle-stroke-width': 1,
            'circle-stroke-color': 'lightgray'
        }
    },
    assets: {
        type: 'circle',
        paint: {
            'circle-radius': [
                'interpolate',
                ['linear'],
                ['zoom'],
                12,
                9,
                18,
                12
            ],
            'circle-color': 'firebrick',
            'circle-stroke-width': 1,
            'circle-stroke-color': 'lightgray',
        },
        layout: {
            'visibility': 'none'
        }
    },
    asset_label: {
        'type': 'symbol',
        'paint': {
            'text-color': 'white',
            'text-opacity': [
                'interpolate',
                ['linear'],
                ['zoom'],
                5,
                0,
                11,
                1
            ]
        },
        'layout': {
            'text-field': ['get', 'address'],
            'text-font': [
                'Open Sans Semibold',
                'Arial Unicode MS Bold'
            ],
            'text-offset': [1, -1],
            'text-anchor': 'bottom-left',
            'visibility': 'none'
        }
    },
    ignition: {
        "type": "fill", 'paint': {
            'fill-color': 'black',
            'fill-opacity': 0.5,
            'fill-outline-color': 'white'
        }
    },
    ignition_outline: {
        "type": "line", 'paint': {
            'line-color': 'red',
            'line-opacity': 0.5,
            'line-width': 2
        }
    }
}

export { layerProps };