import { Grid } from "@mui/material";
import AppPlaybackControl from "./components/playback";
import ResultsContext from "./context/Results";
import SettingsContext from "./context/Settings";
// import DisplayMap from "./ReactiveMap";
import DisplayMap from './Map';
import * as React from 'react';

export default function MapView(props) {
    return (<ResultsContext.Consumer>
        {({ results, updateResults, structures }) => (
            <SettingsContext.Consumer>
                {({ settings, updateSettings }) => (
                    <Grid container>
                        <DisplayMap showStructures={props.showStructures} showAssets={props.showAssets} settings={settings} updateSettings={updateSettings} results={results} updateResults={updateResults} structures={structures} addIncidents={props.addIncidents}></DisplayMap>
                        <AppPlaybackControl settings={settings} updateSettings={updateSettings} results={results} playbackTop={props.playbackTop}></AppPlaybackControl>
                    </Grid>
                )}
            </SettingsContext.Consumer>)}
    </ResultsContext.Consumer>)
}