import Box from '@mui/material/Box';


import { ChevronLeftRounded, Download, MapOutlined } from '@mui/icons-material';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import TimelineIcon from '@mui/icons-material/Timeline';
import { Button, LinearProgress, Tooltip, Typography, Grid, CircularProgress } from '@mui/material';
import DoorbellIcon from '@mui/icons-material/Doorbell';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import * as React from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import ResultsContext from './context/Results';
import SettingsContext from './context/Settings';
import defaultResults from './defaults/results';
import defaultSettings from './defaults/settings';
import ExportView from './ExportView';
import MapView from './MapView';
import fetchIncidentDetails from './services/incident_details';
import StatsView from './StatsView';
import StructureListView from './StructureListView';
import { mergeDeep } from './utils';
import { useNavigate } from "react-router-dom";
import { useAuth0 } from '@auth0/auth0-react';
import PropertyListView from './PropertyListView';
import { hasRole } from './utils';

export default function IncidentDetails(props) {
    const [value, setValue] = React.useState(0);
    const [loading, setLoading] = React.useState(true);
    const [results, setResults] = React.useState(defaultResults);
    const [settings, setSettings] = React.useState(defaultSettings);
    const { user } = useAuth0();
    const { pathname } = useLocation();
    const params = useParams();



    const dt = params.dt;
    const incidentID = params.incidentID;

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    React.useEffect(() => {

        const fetchDetails = async () => {
            try {
                const data = await fetchIncidentDetails(incidentID, user.email);

                data.structures.features = data.structures.features.sort((a, b) => {
                    let aKey = +a.properties.probability;
                    let bKey = +b.properties.probability;
                    return bKey - aKey;
                })

                setResults(data);
                setSettings((existing) => {
                    settings.simulation.ignition = data.ignition;
                    existing.simulation.started = data.ignition.properties.polygon_created;
                    existing.simulation.simulation_name = data.ignition.properties.incident_name;
                    existing.simulation.simulation_context = data.ignition.properties.state;
                    existing.application.mapRendering = true;
                    return existing;
                });
                setLoading(false);
            } catch (err) {
                // navigate("/app/incidents");
                console.error(err);
            }
        }
        fetchDetails(dt, incidentID);
    }, [dt, incidentID]);

    React.useEffect(() => {
        if (params.tabId === "map") {
            setValue(0);
        } else if (params.tabId === "statistics") {
            setValue(1);
        } else if (params.tabId === "structures") {
            setValue(2);
        }
        else if (params.tabId === "assets") {
            setValue(3);
        }
        else if (params.tabId === "export") {
            setValue(4);
        }
    }, [pathname]);



    function a11yProps(index) {
        return {
            id: `vertical-tab-${index}`,
            'aria-controls': `vertical-tabpanel-${index}`,
        };
    };

    const updateResults = (newResults) => {
        setResults(newResults);
    }

    const updateSettings = (newSettings) => {
        setSettings(mergeDeep({}, settings, newSettings))
    }

    const resetSettings = (e) => {
        setSettings(defaultSettings);
    }


    const navigate = useNavigate();

    return (
        <ResultsContext.Provider value={{ results, updateResults, structures: results.structures }}>
            <SettingsContext.Provider value={{ settings, updateSettings, resetSettings }}>
                <Box style={{ width: '100%' }}>
                    <Box sx={{ display: 'flex' }}>
                        <Box sx={{ flexGrow: 2 }}>
                            {(!loading) ? (
                                <ResultsContext.Consumer>
                                    {({ results, updateResults, structures }) => (
                                        <SettingsContext.Consumer>
                                            {({ updateSettings }) => (
                                                <Box >
                                                    <Box sx={{ flexGrow: 1 }}>
                                                        <MapView showStructures={(value !== 3)} showAssets={(value == 3)} settings={settings} structures={structures} updateSettings={updateSettings} results={results} updateResults={updateResults} addIncidents={false}></MapView>
                                                        <Button size='large' variant='elevated' sx={{ backgroundColor: 'black', position: 'absolute', top: 12, left: 12, zIndex: 25 }} onClick={() => navigate(-1)} startIcon={<ChevronLeftRounded></ChevronLeftRounded>}>Back</Button>
                                                    </Box>
                                                    {(value == 1) ? (
                                                        <Box sx={{ width: 256, overflowY: 'auto', position: 'absolute', zIndex: 14, top: 0, right: 36, backgroundColor: 'background.paper' }} alignContent='start'>
                                                            <StatsView settings={settings} structures={structures} updateSettings={updateSettings} results={results} updateResults={updateResults}></StatsView>
                                                        </Box>) : null
                                                    }
                                                    {(value == 2) ? (
                                                        <Box sx={{ width: 256, overflowY: 'auto', maxHeight: '100vh', height: '100vh', position: 'absolute', zIndex: 14, top: 0, right: 36, backgroundColor: 'background.paper' }} alignContent='start'>
                                                            <StructureListView settings={settings} structures={structures} updateSettings={updateSettings} results={results} updateResults={updateResults}></StructureListView>
                                                        </Box>) : null
                                                    }
                                                    {(value == 3) ? (
                                                        <Box sx={{ width: 256, overflowY: 'auto', maxHeight: '100vh', height: '100vh', position: 'absolute', zIndex: 14, top: 0, right: 36, backgroundColor: 'background.paper' }} alignContent='start'>
                                                            <PropertyListView settings={settings} results={results}></PropertyListView>
                                                        </Box>) : null
                                                    }
                                                    {(value == 4) ? (
                                                        < Box sx={{ width: 256, overflowY: 'auto', maxHeight: '100vh', height: '100vh', position: 'absolute', zIndex: 14, top: 0, right: 36, backgroundColor: 'background.paper' }}>
                                                            <ExportView settings={settings} structures={structures} updateSettings={updateSettings} results={results} updateResults={updateResults}></ExportView>
                                                        </Box>) : null
                                                    }
                                                </Box>
                                            )}

                                        </SettingsContext.Consumer>)}
                                </ResultsContext.Consumer>
                            ) : <>
                                <Grid container sx={{ 'width': '100%', 'height': '100vh', position: 'absolute', top: 0, left: 0, zIndex: 25 }} alignContent='center' alignItems='center' justifyContent='center'>

                                    <Typography variant='h6' sx={{ my: 3, mx: 3 }}>Loading...</Typography>
                                    <CircularProgress sx={{ width: 60 }} />

                                </Grid>
                            </>}

                        </Box>
                        <Box >
                            {
                                results && results.probabilities && results.probabilities.features.length ? (


                                    <Tabs textColor="secondary"
                                        orientation="vertical"
                                        variant="scrollable"
                                        value={value}
                                        onChange={handleChange}
                                        sx={{ p: 0, width: 36 }}
                                    >
                                        <Tab sx={{ p: 0, minWidth: 36 }} icon={<Tooltip title='Map View'><MapOutlined style={{
                                            color: 'white', display: "flex",
                                            alignItems: "center",
                                        }} /></Tooltip>} {...a11yProps(0)} />
                                        <Tab sx={{ p: 0, minWidth: 36 }} icon={<Tooltip title='Statistics'><TimelineIcon style={{
                                            color: 'white', display: "flex",
                                            alignItems: "center",
                                        }} /></Tooltip>} {...a11yProps(1)} />

                                        <Tab sx={{ p: 0, minWidth: 36 }} icon={<Tooltip title='Structures'><LocationCityIcon style={{
                                            color: 'white', display: "flex",
                                            alignItems: "center",
                                        }} /></Tooltip>} {...a11yProps(1)} />


                                        <Tab sx={{ p: 0, minWidth: 36 }} icon={<Tooltip title='Assets'><DoorbellIcon style={{
                                            color: 'white', display: "flex",
                                            alignItems: "center",
                                        }} /></Tooltip>} {...a11yProps(1)} />


                                        <Tab sx={{ p: 0, minWidth: 36 }} icon={<Tooltip title='Download'><Download style={{
                                            color: 'white', display: "flex",
                                            alignItems: "center",
                                        }} /></Tooltip>} {...a11yProps(1)} />
                                    </Tabs>
                                ) : null
                            }
                        </Box>
                    </Box>
                </Box >
            </SettingsContext.Provider >
        </ResultsContext.Provider>

    );
}
