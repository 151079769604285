import { useAuth0 } from '@auth0/auth0-react';
import { Button, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { events, logAnalyticsEvent } from '../services/firebase';

const SignupButton = () => {
  const { loginWithRedirect } = useAuth0();

  const handleLogin = async (evt) => {
    loginWithRedirect({
      screen_hint: 'signup',
      redirectUri: window.location.origin + "/app"
    });
  }

  return (
    <Button
      variant='elevated'
      onClick={handleLogin}
    >
      Sign Up
    </Button >
  )
};

export default SignupButton;