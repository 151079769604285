import MUIDataTable from "mui-datatables";
import { Typography, Grid, Button } from "@mui/material";
import React, { useEffect, useState } from 'react';
import { ChevronLeftRounded } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

export default function TokenListView() {
    const navigate = useNavigate();
    const [tokenList, setTokenList] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const req = await fetch('https://api-core-production-63vbfoxayq-uw.a.run.app/_internal/management/tokens')
            const res = await req.json();
            setTokenList(res);
        }
        fetchData();
    }, [])

    const columns = [
        {
            name: "program",
            label: "Program"
        },
        {
            name: "token",
            label: "Token"
        },
        {
            name: "redeemed_at",
            label: "Redeemed"
        }
    ]
    return (
        <Grid container sx={{ mx: 4, my: 4, maxHeight: '100vh', overflowY: 'auto' }}>
            <Grid item xs={12} >
                <Button onClick={() => { navigate(-1) }} startIcon={<ChevronLeftRounded />}>Back</Button>
            </Grid>
            <Grid item xs={8} sx={{ mb: 2 }}>
                <Typography variant='h3'>Available Authentication Tokens</Typography>
            </Grid>
            <Grid item xs={12} sx={{ my: 1 }}>
                <MUIDataTable
                    title={""}
                    data={tokenList}
                    columns={columns}
                    options={{
                        pagination: true,
                        rowsPerPage: 100,
                        print: false,
                    }}
                />
            </Grid>

        </Grid>
    )

}