const defaultResults = {
    "perimeters": { "type": "FeatureCollection", "features": [] },
    "structures": { "type": "FeatureCollection", "features": [] },
    "probabilities": { "type": "FeatureCollection", "features": [] },
    "forecast": [],
    'data': null,
    "impactedProperties": { "type": "FeatureCollection", "features": [] },
    "urls": {}
}

export default defaultResults;