// Import the functions you need from the SDKs you need
import { getAnalytics, logEvent } from "firebase/analytics";
import { initializeApp } from "firebase/app";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDE_M7Lki5qZhqCUl0ytwmIItZwW2c8cFA",
    authDomain: "willow-studio.firebaseapp.com",
    projectId: "willow-studio",
    storageBucket: "willow-studio.appspot.com",
    messagingSenderId: "456844037987",
    appId: "1:456844037987:web:f00ea3f53aec3d7d38cd56",
    measurementId: "G-V98DVC0CG6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function logAnalyticsEvent(event_type, event_data) {
    if (!event_type) {
        throw new Error(`Event ${event_type} unknown`);
    }
    return logEvent(analytics, event_type, event_data);
}

const events = {
    upload_policy: 'policy:upload',
    delete_policy: 'policy:delete',
    view_policy: 'policy:view',
    upload_policy_cancel: 'policy:upload_cancel',
    upload_policy_error: 'policy:upload_error',
    log_in: 'login',
    log_out: 'logout',
    sign_up_start: 'signup:start',
    sign_up_complete: 'signup:complete',
    run_simulation_start: 'simulation:start',
    run_simulation_complete: 'simulation:complete',
    run_simulation_error: 'simulation:error',
    set_ignition_point: 'ignition:point',
    set_ignition_incident: 'ignition:incident',
    set_weather_provider: 'weather:set_provider',
    download_structures: 'download:structures',
    download_perimeters: 'download:perimeters',
    set_duration: 'duration:set',
    simulation_params_reset: 'simulation:reset',
    use_playback_timeline: 'playback:timeline',
    use_playback_probability: 'platback_probability',
    select_incident: 'incident:select',
    request_support: 'support:email',
    set_realization_count: 'count:set',
    redeem_complete: 'redeem:complete',
    redeem_error: 'redeem:error',
    update_policy: 'policy:update'
}

export { app, analytics, logAnalyticsEvent, events };
