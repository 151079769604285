import mapboxgl from '!mapbox-gl'; // eslint-disable-line
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import * as Turf from '@turf/turf';
import 'mapbox-gl/dist/mapbox-gl.css';
import React, { useEffect, useRef, useState } from 'react';
import resolveIncidentGeometry from './services/incidents';
import { layerProps } from './components/mapStyle';
import moment from 'moment';


mapboxgl.accessToken = 'pk.eyJ1Ijoic2ZhcmxleTIiLCJhIjoiY2lmeWVydWtkNTJpb3RmbTFkdjQ4anhrMSJ9.jRJCOGU1AOHfNXHH7cwU7Q';

function featureCollection(features) {
    return {
        "type": "FeatureCollection",
        "features": (features && features.length) ? features : []
    }
}

class ResetMapViewControl {
    onClick() {
        this._map.flyTo({
            pitch: 0,
            bearing: 0
        })
    }
    onAdd(map) {
        this._map = map;
        this._container = document.createElement('div');
        this._container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';
        this._container.addEventListener('contextmenu', (e) => e.preventDefault());
        this._container.addEventListener('click', (e) => this.onClick());

        this._container.innerHTML =
            '<div class="tools-box">' +
            '<button>' +
            '<span class="mapboxgl-ctrl-icon fas fa-globe fa-lg" aria-hidden="true" title="Reset Map View"></span>' +
            '</button>' +
            '</div>';

        return this._container;
    }
}

class TiltMapViewControl {

    onClick() {
        this._map.flyTo({
            pitch: 60
        })
    }
    onAdd(map) {
        this._map = map;
        this._container = document.createElement('div');
        this._container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';
        this._container.addEventListener('contextmenu', (e) => e.preventDefault());
        this._container.addEventListener('click', (e) => this.onClick());

        this._container.innerHTML =
            '<div class="tools-box">' +
            '<button>' +
            '<span class="mapboxgl-ctrl-icon fas fa-mountain fa-lg" aria-hidden="true" title="Tilt Map View"></span>' +
            '</button>' +
            '</div>';

        return this._container;
    }
}


export default function DisplayMap(props) {
    const [initialized, setInitialized] = useState(false);
    const mapContainer = useRef(null);
    const [ignition, setIgnition] = useState(null);
    const map = useRef(null);

    const addIgnition = async (e, map) => {
        const features = map.queryRenderedFeatures(e.point);
        const feature = features[0];
        let geometry;
        let ignitionFeatureName = null;
        if (feature && feature.source === 'incidents') {
            ignitionFeatureName = feature.properties.poly_IncidentName;
            const geom = await resolveIncidentGeometry(ignitionFeatureName);
            geometry = { type: 'Feature', properties: feature.properties, geometry: geom };

        } else {
            geometry = Turf.buffer({
                "type": "Feature",
                "properties": {},
                "geometry": { "type": "Point", "coordinates": [e.lngLat.lng, e.lngLat.lat] }
            }, 50, { units: 'meters' })
        }

        map.getSource('ignition').setData(featureCollection([geometry]));
        const newSettings = {
            simulation: {
                ignition: geometry,
                ignitionFeatureName: ignitionFeatureName
            },
            application: {
                map: {
                    mode: null
                }
            }
        }

        const centroid = Turf.centroid(geometry);
        map.flyTo({
            center: centroid.geometry.coordinates,
            zoom: 13
        })

        props.updateSettings(newSettings);
        map.off('click', addIgnition)
    }


    const addResult = () => {
        if (props.results && props.results.perimeters.features) {
            map.current.getSource('spread').setData(props.results.perimeters);
            map.current.getSource('probabilities').setData(props.results.probabilities);

        }
        if (props.structures && props.structures.features) {
            if (props.results && props.results.perimeters.features && props.results.perimeters.features) {
                map.current.getSource('structure-point').setData(props.results.structures);
            }
        }

        if (props.results.impactedProperties && props.results.impactedProperties.features) {
            map.current.getSource('asset-point').setData(props.results.impactedProperties)
        }


        const newSettings = {
            application: {
                mapRendering: false
            }
        }
        props.updateSettings(newSettings);
        map.current.off('idle', addResult);
    }

    useEffect(() => {
        if (!map.current) { return };
        if (props.showStructures) {
            map.current.setLayoutProperty('structure-point', 'visibility', 'visible')
            map.current.setLayoutProperty('asset-point', 'visibility', 'none')
            map.current.setLayoutProperty('asset-label', 'visibility', 'none')
        } else {
            map.current.setLayoutProperty('structure-point', 'visibility', 'none');
            map.current.setLayoutProperty('asset-point', 'visibility', 'visible')
            map.current.setLayoutProperty('asset-label', 'visibility', 'visible')
        }
    }, [props.showStructures]);

    useEffect(() => {
        if (!map.current) { return };
        if (props.showStructures) {
            map.current.setLayoutProperty('structure-point', 'visibility', 'visible')
        } else {
            map.current.setLayoutProperty('structure-point', 'visibility', 'none')
        }
    }, [props.showAssets]);

    useEffect(() => {
        if (map.current) return; // initialize map only once
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: props.settings.application.map.style,
            center: [-116, 37],
            zoom: 3,
            hash: true
        });

        map.current.addControl(new TiltMapViewControl(), 'bottom-right');
        map.current.addControl(new ResetMapViewControl(), 'bottom-right');

        const geocoder = new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            collapsed: true,
            mapboxgl: mapboxgl,
            countries: 'us',
            position: 'bottom-right',
            types: 'address,place,locality',
            localGeocoder: (e) => {
                const regex = new RegExp(/[-]?\d+,[-]?\d+/);
                const match = e.match(regex);

                if (!match) {
                    return [];
                }
                const [lng, lat] = e.split(",");
                if (+lng > -180 && +lng < 180 && +lat > -90 && +lat < 90) {
                    return [{
                        place_name: `Lng: ${lng} Lat: ${lat}`,
                        geometry: {
                            type: 'Point',
                            coordinates: [lng, lat]
                        },
                        properties: {}
                    }]
                }

            }
        });

        geocoder.on('result', (e) => {
        })
        map.current.addControl(geocoder, 'bottom-right');

        map.current.on('load', () => {
            map.current.addSource("ignition", { 'type': 'geojson', data: featureCollection() });
            map.current.addSource("spread", { 'type': 'geojson', data: featureCollection() });
            map.current.addSource("structure-point", { 'type': 'geojson', data: featureCollection() });
            map.current.addSource("asset-point", { 'type': 'geojson', data: featureCollection() });

            map.current.addSource("probabilities", { 'type': 'geojson', data: featureCollection() });
            map.current.addSource('incidents', { 'type': 'geojson', data: 'https://services3.arcgis.com/T4QMspbfLg3qTGWY/arcgis/rest/services/Current_WildlandFire_Perimeters/FeatureServer/0/query?where=1%3D1&outFields=poly_IncidentName&outSR=4326&f=geojson' })


            if (props.addIncidents) {
                map.current.addLayer({
                    'source': "incidents", "id": "incidents_fill", ...layerProps.incidents
                })
            }

            map.current.addLayer({
                'source': "spread", "id": "spread_line", ...layerProps.perimeters
            })

            map.current.addLayer({
                source: 'probabilities', id: 'probabilities', ...layerProps.probabilities
            }, 'waterway-label');



            map.current.addLayer({
                source: 'structure-point', id: 'structure-point', ...layerProps.structures
            }, 'waterway-label')

            map.current.addLayer({
                source: 'asset-point', id: 'asset-point', ...layerProps.assets
            }, 'waterway-label')
            map.current.addLayer({
                source: 'asset-point', id: 'asset-label', ...layerProps.asset_label
            }, 'waterway-label')


            map.current.addLayer({
                'source': "ignition", "id": "ignition", ...layerProps.ignition
            })
            map.current.addLayer({
                'source': "ignition", "id": "ignition_outline", ...layerProps.ignition_outline
            })


            // Create a popup, but don't add it to the map yet.
            const popup = new mapboxgl.Popup({
                closeButton: false,
                closeOnClick: false
            });


            map.current.on('mouseenter', 'structure-point', (e) => {
                // Change the cursor style as a UI indicator.
                map.current.getCanvas().style.cursor = 'pointer';

                // Copy coordinates array.
                const coordinates = e.features[0].geometry.coordinates.slice();
                const addrprops = e.features[0].properties;

                const isInRange = !isNaN(+addrprops.probability) && +addrprops.probability > 0;
                let description;
                if (isInRange) {
                    console.log(addrprops)
                    const timeOfArrival = moment().add(addrprops.eta_min, 'm').format("MM/DD/YYYY hh:mm A");
                    let probabilityString;
                    let rawProbability = addrprops.probability;
                    if (rawProbability < 0.2) {
                        probabilityString = 'Low';
                    } else if (rawProbability < 0.5) {
                        probabilityString = 'Possible';
                    } else if (rawProbability < 0.7) {
                        probabilityString = 'Likely';
                    } else {
                        probabilityString = 'Very Likely';
                    }
                    console.log(timeOfArrival)
                    description = `<h4 class='popup-header'>${addrprops.number} ${addrprops.street} ${addrprops.unit}</h4>
                    <p class='popup-body'><strong>Status</strong>: Threatened</p>
                    <p class='popup-body'><strong>Earliest time of arrival</strong>: ${timeOfArrival} </p>
                    <p class='popup-body'><strong>Probability</strong>: ${probabilityString} (${(rawProbability * 100).toFixed(1)}%)</p>
                `
                } else {
                    description = `<h4 class='popup-header'>${addrprops.number} ${addrprops.street} ${addrprops.unit}</h4>
                    <p class='popup-body'><strong>Status</strong>: Monitoring</p>
                `
                }


                // Populate the popup and set its coordinates
                // based on the feature found.
                popup.setLngLat(coordinates).setHTML(description).addTo(map.current);
            });

            map.current.on('mouseleave', 'structure-point', () => {
                map.current.getCanvas().style.cursor = '';
                popup.remove();
            });

        });
    });

    // register the ignition-on-click handler
    useEffect(() => {
        if (map.current && props.settings.application.map.mode == 'ignition') {

            map.current.on('click', async (e) => { await addIgnition(e, map.current) });
        }

    }, [props.settings.application.map.mode]);

    /*
        Set map view once the map has initialized once 
    */
    useEffect(() => {
        if (!initialized) {
            if (props.results && props.results.perimeters && props.results.perimeters.features.length) {
                map.current.once('idle', () => {
                    setInitialized(true);
                    const newSettings = {
                        application: {
                            mapRendering: false
                        }
                    }
                    props.updateSettings(newSettings);
                })
            }
        }
    }, [props.results])

    // set the bbox when the map is first loaded 
    useEffect(() => {
        if (!initialized) {
            return;
        }
        if (props.results && props.results.probabilities && props.results.probabilities.features.length) {
            const bbox = Turf.bbox(props.results.probabilities);
            map.current.fitBounds([
                [bbox[0], bbox[1]],
                [bbox[2], bbox[3]]
            ])
        }
    }, [initialized])

    // Handle map playback and animation

    const setMapFilter = () => {
        if (!props.settings.application.mapFilter || props.settings.application.mapFilter == 0) {
            return null;
        } else {
            const minutes = props.settings.application.mapFilter * 60;
            const window = 30;
            const minMinutes = minutes - window;
            const maxMinutes = minutes + window;
            const filter = ["all", ['>=', ['to-number', ['get', 'elapsed_minutes']], minMinutes], ['<=', ['to-number', ['get', 'elapsed_minutes']], maxMinutes]]

            map.current.setFilter('spread_line', filter)
        }
    }
    // trigger the map filter once the map is initialized 
    // and whenever the map filter changes
    useEffect(() => {
        if (!map.current.isStyleLoaded()) {
            return;
        } else {
            setMapFilter();
        }
    }, [props.settings.application.mapFilter, initialized])


    // update the results to reflect them on mapview
    useEffect(() => {

        if (map.current) {
            if (map.current.isStyleLoaded()) {
                addResult();
            } else {
                map.current.once('idle', addResult);
            }
        }
    }, [props.results, props.structures])

    // set the ignition polygon if it changes 
    useEffect(() => {
        let ign;
        if (!props.settings.simulation.ignition) {
            ign = { type: 'FeatureCollection', features: [] }
        } else {
            ign = props.settings.simulation.ignition;
        }
        setIgnition(JSON.stringify(ign));
    }, [props.settings.simulation.ignition, initialized]);

    useEffect(() => {
        const ign = JSON.parse(ignition);
        if (map.current.isStyleLoaded()) {
            map.current.getSource('ignition').setData(ign);
        } else {
            map.current.once('idle', () => {
                map.current.getSource('ignition').setData(ign);
            });
        }
    }, [ignition])

    return (
        <div className='map-outer'>
            <div ref={mapContainer} className="map-container" />
        </div>
    );
}

