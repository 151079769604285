import { Typography, Button, Grid } from '@mui/material';
import MUIDataTable from 'mui-datatables';
import { ChevronLeftRounded, DeleteForever, Refresh, Visibility } from '@mui/icons-material';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Line, LineChart, XAxis, YAxis, Tooltip, Bar, BarChart } from 'recharts';


const HOST = 'https://api-core-production-63vbfoxayq-uw.a.run.app'

function judgeIOU(iou) {
    if (iou < 0.4) {
        return "Poor";
    } else if (iou < 0.6) {
        return "Fair";
    } else if (iou < 0.8) {
        return "Good";
    } else {
        return "Excellent";
    }
}

export default function GroundtruthList() {
    const navigate = useNavigate();
    const [results, setResults] = useState([]);
    const [dateSummary, setDateSummary] = useState([]);
    const [regionSummary, setRegionSummary] = useState([]);
    const [overallSummary, setOverallSummary] = useState({
        mean_iou: 0,
        mean_groundtruth_area: 0,
        mean_model_area: 0
    });

    const columns = [
        {
            name: "incident_name",
            label: "Incident Name",
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            name: "irwin_incident_id",
            label: "Incident ID",
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            name: "polygon_date",
            label: "Last Model Run",
            options: {
                filter: false,
                sort: true
            }
        }
    ];


    useEffect(() => {
        const fetchData = async () => {
            const req = await fetch(`${HOST}/groundtruth`);
            const res = await req.json();
            setResults(res);
        }
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const minDate = moment().subtract(14, 'days').format()
            const req = await fetch(`${HOST}/groundtruth_/summary?min_date=${minDate}&summarize_by=region`);
            const res = await req.json();

            setRegionSummary(res.sort((a, b) => {
                return a.mean_iou - b.mean_iou
            }));
        }
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const minDate = moment().subtract(14, 'days').format()
            const req = await fetch(`${HOST}/groundtruth_/summary?min_date=${minDate}&summarize_by=date`);

            const res = await req.json();

            setDateSummary(res);
        }
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const minDate = moment().subtract(14, 'days').format()
            const req = await fetch(`${HOST}/groundtruth_/summary?min_date=${minDate}`);
            const res = await req.json();
            setOverallSummary(res);
        }
        fetchData();
    }, []);

    console.log(overallSummary)
    return (
        <Grid container sx={{ mx: 8, my: 4, maxHeight: '100vh', overflowY: 'auto' }}>
            <Grid item xs={12} >
                <Button onClick={() => { navigate(-1) }} startIcon={<ChevronLeftRounded />}>Back</Button>
            </Grid>
            <Grid item xs={8} sx={{ mb: 2 }}>
                <Typography variant='h3'>Groundtruth Dashboard</Typography>
            </Grid>
            <Grid item xs={12} sx={{mb: 2}}>
            <Typography variant='h5'>Mean IOU: {overallSummary['mean_iou'].toLocaleString()}</Typography>
            <Typography variant='h5'>Mean Area Delta: {(((overallSummary['mean_model_area'] - overallSummary['mean_groundtruth_area']) / overallSummary['mean_groundtruth_area'])*100).toLocaleString()}%</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant='h6'>Model Performance by Date</Typography>
                <Typography variant='subtitle2'>Average IOU by Day</Typography>
                <LineChart
                    width={500}
                    height={200}
                    data={dateSummary}
                    margin={{
                        top: 5,
                        right: 5,
                        left: 0,
                        bottom: 0,
                    }}
                >
                    <YAxis tick={{ fill: 'white' }} />
                    <XAxis dataKey={'date'} tick={{ fill: 'white' }} />
                    <Line key={'test'} type="basis" dataKey={'mean_iou'} stroke={'#F74864'} dot={true} strokeWidth={2} />
                    <Tooltip contentStyle={{ backgroundColor: 'black' }} itemStyle={{ color: 'white' }} />
                </LineChart>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Typography variant='h6'>Model Performance by Region</Typography>
                <Typography variant='subtitle2'>Average IOU by State</Typography>
                <BarChart
                    width={350}
                    height={200}
                    data={regionSummary}
                    margin={{
                        top: 5,
                        right: 5,
                        left: 0,
                        bottom: 0,
                    }}
                >
                    <YAxis tick={{ fill: 'white' }} />
                    <XAxis dataKey={'region'} tick={{ fill: 'white' }} />
                    <Tooltip contentStyle={{ backgroundColor: 'black' }} itemStyle={{ color: 'white' }} cursor={false} />
                    <Bar key={'test'} type="basis" dataKey={'mean_iou'} stroke={'#2B293A'} fill={'#225D9E'} dot={true} strokeWidth={2} />
                </BarChart>
            </Grid>

            <Grid item xs={12} sm={6}>
                <Typography variant='h6'>Intersection vs. Union</Typography>
                <Typography variant='subtitle2'>Overlapping Acres (intersection) vs. Distinct Acres (union)</Typography>
                <BarChart
                    width={350}
                    height={200}
                    data={[
                        { 'key': 'intersection', 'acres': overallSummary['mean_intersection'] * 0.000247105 },
                        { 'key': 'union', 'acres': overallSummary['mean_union'] * 0.000247105 },
                    ]}
                    margin={{
                        top: 5,
                        right: 5,
                        left: 0,
                        bottom: 0,
                    }}
                >
                    <YAxis tick={{ fill: 'white' }} />
                    <XAxis dataKey={'key'} tick={{ fill: 'white' }} />
                    <Tooltip contentStyle={{ backgroundColor: 'black' }} itemStyle={{ color: 'white' }} cursor={false} />
                    <Bar key={'test'} type="basis" dataKey={'acres'} fill={'#225D9E'} dot={true} strokeWidth={2} />
                </BarChart>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Typography variant='h6'>Prediction Area</Typography>
                <Typography variant='subtitle2'>Model Acres vs. Groundtruth Acres</Typography>
                <BarChart
                    width={350}
                    height={200}
                    data={[
                        { 'key': 'model', 'acres': overallSummary['mean_model_area'] * 0.000247105 },
                        { 'key': 'groundtruth', 'acres': overallSummary['mean_groundtruth_area'] * 0.000247105 },
                    ]}
                    margin={{
                        top: 5,
                        right: 5,
                        left: 0,
                        bottom: 0,
                    }}
                >
                    <YAxis tick={{ fill: 'white' }} />
                    <XAxis dataKey={'key'} tick={{ fill: 'white' }} />
                    <Tooltip contentStyle={{ backgroundColor: 'black' }} itemStyle={{ color: '#225D9E' }} cursor={false} />
                    <Bar key={'test'} type="basis" dataKey={'acres'}  fill={'#225D9E'} dot={true} strokeWidth={2} />
                </BarChart>
            </Grid>

            <Grid item xs={10} sx={{ mt: 3, mb: 6 }}>
                <MUIDataTable
                    title={"Recent Model Runs"}
                    data={results}
                    columns={columns}
                    options={{
                        pagination: true,
                        rowsPerPage: 100,
                        print: false,
                        onRowClick: (row, meta) => {
                            const ridx = meta.dataIndex;
                            const item = results[ridx];
                            navigate(`/app/groundtruth/${item.irwin_incident_id}/${item.polygon_date}`)
                        }
                    }}
                />
            </Grid>
        </Grid>
    )
}