import * as toGeojson from '@tmcw/togeojson';
import * as Turf from '@turf/turf';
import { unzip, unzipSync } from 'fflate';
import { DOMParser } from 'xmldom';


export function hasRole(user, role) {
    const roles = user['willow-studio/roles'];
    console.log(roles);
    if (!roles){
        return false;
    }
    
    return roles.includes(role); 
}


/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export function isObject(item) {
    return (item && typeof item === 'object' && !Array.isArray(item));
}

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export function mergeDeep(target, ...sources) {
    if (!sources.length) return target;
    const source = sources.shift();

    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!target[key]) Object.assign(target, { [key]: {} });
                mergeDeep(target[key], source[key]);
            } else {
                Object.assign(target, { [key]: source[key] });
            }
        }
    }

    return mergeDeep(target, ...sources);
}

export function assignColors(perimeters) {
    const colorMap = {};
    perimeters.features.forEach((g) => {
        const id = g.properties.realization_id;
        if (!colorMap[id]) {
            colorMap[id] = getRandomColor();
        }
        const thisColor = colorMap[id];
        g.properties._style_color = thisColor;
    });
    return perimeters
}


// multithreaded = false is slower and blocks the UI thread if the files
// inside are compressed, but it can be faster if they are not.
export const getFiles = async (zipFile, multithreaded = true) => {
    const zipBuffer = new Uint8Array(await zipFile.arrayBuffer());
    const unzipped = multithreaded
        ? await new Promise((resolve, reject) => unzip(
            zipBuffer,
            (err, unzipped) => err
                ? reject(err)
                : resolve(unzipped)
        ))
        : unzipSync(zipBuffer);
    const fileArray = Object.keys(unzipped)
        .filter(filename => unzipped[filename].length > 0)
        .map(filename => new File([unzipped[filename]], filename));
    return fileArray;
}

const getIgnitionData = (data) => {
    let perim = data.features.filter((d) => {
        return d.id == 'perimeter';
    })[0];
    if (!perim) {
        perim = data.features[0];
    }

    if (!perim) {
        throw new Error("Could not parse KML -- not valid ignition shapes found.");
    }

    const acreage = Turf.area(perim) / 4047

    if (acreage > 25) {
        throw new Error(`Input file cannot be greater than 25 acres. Found size: ${acreage} acres`);
    };

    return perim
}

export const kmlFileToGeojson = (file) => {
    return new Promise((resolve, reject) => {
        if (!file) return;
        var reader = new FileReader();
        reader.readAsText(file, "UTF-8");
        reader.onload = (evt) => {
            const content = evt.target.result;
            const kml = new DOMParser().parseFromString(content);
            const gj = toGeojson.kml(kml, { styles: true });
            const ignition = getIgnitionData(gj);
            return resolve(ignition);
        }
        reader.onerror = (evt) => {
            return reject(evt.target.result)
        }
    })
}

export function getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}
