import { useAuth0 } from '@auth0/auth0-react';
import { ChevronLeftRounded, DeleteForever, Refresh, Visibility } from '@mui/icons-material';
import { Button, Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow, List, ListItem, Typography, Box } from '@mui/material';
import { useEffect, useState, useRef } from 'react';
import CurrencyFormat from 'react-currency-format';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { events, logAnalyticsEvent } from './services/firebase';
import * as React from 'react';
import MUIDataTable from "mui-datatables";
import mapboxgl from '!mapbox-gl'; // eslint-disable-line
import ShortcutIcon from '@mui/icons-material/Shortcut';
mapboxgl.accessToken = 'pk.eyJ1Ijoic2ZhcmxleTIiLCJhIjoiY2lmeWVydWtkNTJpb3RmbTFkdjQ4anhrMSJ9.jRJCOGU1AOHfNXHH7cwU7Q';


const MONITORING_HOST = `https://api-core-production-63vbfoxayq-uw.a.run.app`
// const MONITORING_HOST = 'http://localhost:8000';

const resolveIncidentID = async (name) => {
	const req = await fetch(`${MONITORING_HOST}/_internal/incident/resolve/${name}`);
	const res = await req.json();
	return res.properties.irwin_id;
}

function MonitoringMap(props) {
	const mapContainer = useRef(null);
	const map = useRef(null);
	const { user } = useAuth0();
	const params = useParams();

	useEffect(() => {
		if (!map.current) {
			return;
		}
		if (!props.clickedFeature) {
			return;
		}
		const coords = props.clickedFeature.geometry.coordinates;
		map.current.flyTo({
			center: coords,
			zoom: 14
		})
	}, [props.clickedFeatureID])


	useEffect(() => {
		if (map.current) return; // initialize map only once

		map.current = new mapboxgl.Map({
			container: mapContainer.current,
			style: "mapbox://styles/sfarley2/cl6xxhf9g000n14qszf5g221y",
			center: [-92.41, 45.51],
			zoom: 3.5,
			hash: true
		});

		map.current.on('load', () => {
			const envelopeUrl = `${MONITORING_HOST}/monitoring/incidents/envelope`
			map.current.addSource('envelopes', { 'type': 'geojson', data: envelopeUrl });
			map.current.addLayer({
				'type': 'fill',
				'id': 'envelope',
				'source': 'envelopes',
				'paint': {
					'fill-color': [
						'interpolate',
						['linear'],
						['zoom'],
						3,
						'#ffcc00',
						12,
						'#e67300'
					],
					'fill-opacity': [
						'interpolate',
						['linear'],
						['zoom'],
						8,
						1,
						12,
						0.8
					],
				}
			}, 'waterway-label');

			const markerUrl = `${MONITORING_HOST}/policies/${user.email}/impacted?bbox=-180,0,180,90&policy_id=${props.policyID}`
			map.current.addSource('markers', { 'type': 'geojson', data: markerUrl });
			map.current.addLayer({
				'type': 'circle',
				'id': 'markers',
				'source': 'markers',
				'paint': {
					'circle-color': [
						'case',
						['to-boolean', ['get', 'is_threatened']],
						'firebrick',
						'darkcyan',
					],
					'circle-radius': [
						'interpolate',
						['linear'],
						['zoom'],
						12,
						6,
						15,
						9
					],
					'circle-opacity': 0.9,
					'circle-stroke-color': 'white',
					'circle-stroke-width': 1
				}
			});
			map.current.addLayer({
				'type': 'symbol',
				'id': 'marker-label',
				'source': 'markers',
				'paint': {
					'text-color': 'white',
					'text-opacity': [
						'interpolate',
						['linear'],
						['zoom'],
						10,
						0,
						11,
						1
					]
				},
				'layout': {
					'text-field': ['get', 'address'],
					'text-font': [
						'Open Sans Semibold',
						'Arial Unicode MS Bold'
					],
					'text-offset': [1, 1],
					'text-anchor': 'bottom-left'
				}
			});
		})
	}, [])

	return (
		<div className='map-outer-monitoring'>
			<div ref={mapContainer} className="map-container-monitoring" />
			<Box sx={{ position: 'absolute', left: 0, top: 0, zIndex: 250 }}>
				<Grid container >

					<Grid item xs={12}><Box height={12} width={12} sx={{ backgroundColor: '#e67300', display: 'inline-block' }}></Box><Typography variant='caption' sx={{ display: 'inline', paddingLeft: 1 }}>48 Hour Forecast Perimeter</Typography></Grid>
					<Grid item xs={12}><Box height={12} width={12} sx={{ backgroundColor: 'darkcyan', display: 'inline-block' }}></Box><Typography variant='caption' sx={{ display: 'inline', paddingLeft: 1 }}>Non-Threatened Asset</Typography></Grid>
					<Grid item xs={12}><Box height={12} width={12} sx={{ backgroundColor: 'firebrick', display: 'inline-block' }}></Box><Typography variant='caption' sx={{ display: 'inline', paddingLeft: 1 }}>Threatened Asset</Typography></Grid>

				</Grid>
			</Box>
		</div>
	);
}

function IncidentList(props) {
	const navigate = useNavigate();
	const handleClick = async (vid) => {
		const incidentID = await resolveIncidentID(vid);
		navigate(`/app/incidents/${incidentID}/assets`);
	}
	return (
		<List>
			{props.value.map((vid) => {
				return <ListItem key={vid}>
					<Button startIcon={<ShortcutIcon />} onClick={() => { handleClick(vid) }}>
						<Typography variant='caption'></Typography>{vid}
					</Button>
				</ListItem>
			})}
		</List>
	);
};

const columns = [
	{
		name: "address",
		label: "Address",
		options: {
			filter: false,
			sort: true,
		}
	},
	{
		name: "value",
		label: "Value",
		options: {
			filter: false,
			sort: true,
		}
	},
	{
		name: "threatened_by",
		label: "Threatened By",
		options: {
			filter: false,
			sort: true,
			customBodyRender: (value, tableMeta, updateValue) => {
				return <IncidentList value={value}></IncidentList>
			}
		}
	}
];


export default function MonitoringPolicyDetails() {
	const [isFiltered, setIsFiltered] = useState(false);
	const [searchVal, setSearchVal] = useState("");
	const [threatened, setThreatened] = useState({ features: [], summary: {
		total_value: 0,
		threatened_value: 0
	}, alerting: {} });
	const [loading, setLoading] = useState(false);
	const [clickedFeature, setClickedFeature] = useState(null);
	const [clickedFeatureID, setClickedFeatureID] = useState(null);

	const { user } = useAuth0();
	const params = useParams();
	const navigate = useNavigate();

	const fetchThreatened = async () => {
		setLoading(true);
		const req = await fetch(`${MONITORING_HOST}/policies/${user.email}/${params.policyID}`);
		const res = await req.json();

		res.features.forEach((feature) => {
			feature.threatened_by = (feature.properties.threatened_by) ? feature.properties.threatened_by : [];
			feature.value = `$${feature.properties.value.toLocaleString()}`;
			feature.address = feature.properties.address;
		})
		res.features = res.features.sort((a, b) => {
			const al = (a.threatened_by) ? a.threatened_by.length : 0;
			const bl = (b.threatened_by) ? b.threatened_by.length : 0;
			return (bl - al)
		})

		setThreatened(res);
		setLoading(false);
		logAnalyticsEvent(events.view_policy);
	}

	useEffect(() => {
		fetchThreatened();
	}, [])

	return <Grid container sx={{ mx: 8, my: 4, maxHeight: '100vh', overflowY: 'auto' }}>
		<Grid item xs={12} >
			<Button onClick={() => { navigate(-1) }} startIcon={<ChevronLeftRounded />}>Back</Button>
		</Grid>
		<Grid item xs={12} sx={{ my: 1 }}>
			<Typography variant='h3'>{threatened.name}</Typography>
		</Grid>
		<Grid item xs={12} sx={{ my: 1 }}>
			<Typography variant='h6'>{threatened.summary.threatened_number} / {threatened.summary.total_count} Assets Threatened</Typography>
			<Typography variant='h6'>
				${threatened.summary.threatened_value.toLocaleString()} / ${threatened.summary.total_value.toLocaleString()} Threatened
			</Typography>
			<IconButton variant='contained' color='primary' onClick={() => {
				fetchThreatened();
			}}><Refresh /></IconButton>
		</Grid>

		<Grid item xs={5} sx={{ mb: 3 }} >
			{(threatened && threatened.features) ? (
				<MUIDataTable
					title={""}
					data={threatened.features}
					columns={columns}
					options={{
						pagination: true,
						rowsPerPage: 100,
						print: false,
						onRowClick: (row, meta) => {
							const ridx = meta.dataIndex;
							const item = threatened.features[ridx];
							setClickedFeature(item);
							setClickedFeatureID(item.properties.address);
						}
					}}
				/>
			) : null}
		</Grid>
		<Grid item xs={6} sx={{ pl: 2, 'height': '100vh', maxHeight: '100vh' }}>
			<MonitoringMap policyID={params.policyID} clickedFeatureID={clickedFeatureID} clickedFeature={clickedFeature}></MonitoringMap>
		</Grid>

	</Grid >


}