import { Table, TableBody, TableCell, TableHead, TableRow, Box, Button } from '@mui/material';
import * as React from 'react';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import { useNavigate } from 'react-router-dom';

export default function PropertyListView(props) {
    const navigate = useNavigate();
    return <Box>
        <Button onClick={() => {
            navigate("/app/monitoring");
        }} startIcon={<CircleNotificationsIcon></CircleNotificationsIcon>}>Go to Monitoring Settings</Button>
        <Table sx={{ maxHeight: '100vh', overflowY: 'auto' }}>
            <TableHead>
                <TableRow>
                    <TableCell>Address</TableCell>
                    <TableCell>Value</TableCell>
                    <TableCell>Policy Name</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
            {(props.results && props.results.impactedProperties && props.results.impactedProperties.features.length) ? props.results.impactedProperties.features.map((feature) => {
                return <TableRow key={feature.address}>
                    <TableCell>{feature.properties.address}</TableCell>
                    <TableCell>${feature.properties.value.toLocaleString()}</TableCell>
                    <TableCell>{feature.properties.policy_name}</TableCell>
                </TableRow>
            }) : <TableRow>
                <TableCell>No threatened assets in your monitoring policies or <strong>Monitoring</strong> is not enabled on your account.</TableCell>
            </TableRow>}
        </TableBody>
        </Table>
    </Box>
}