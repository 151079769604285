import { useAuth0 } from '@auth0/auth0-react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line
import {
    Button, Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow, List, ListItem,
    Typography, Box, CircularProgress, Select, MenuItem
} from '@mui/material';
import { useEffect, useState, useRef } from 'react';
import CurrencyFormat from 'react-currency-format';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { events, logAnalyticsEvent } from './services/firebase';
import React from 'react';
import ReactSpeedometer from "react-d3-speedometer"
import * as Turf from '@turf/turf';

mapboxgl.accessToken = 'pk.eyJ1Ijoic2ZhcmxleTIiLCJhIjoiY2lmeWVydWtkNTJpb3RmbTFkdjQ4anhrMSJ9.jRJCOGU1AOHfNXHH7cwU7Q';

const GROUNDTRUTH_HOST = 'https://api-core-production-63vbfoxayq-uw.a.run.app'
// const GROUNDTRUTH_HOST = 'http://localhost:8000'

function GroundtruthMap(props) {
    const mapContainer = useRef(null);
    const map = useRef(null);
    const { user } = useAuth0();
    const params = useParams();



    useEffect(() => {
        if (map.current) return; // initialize map only once

        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: "mapbox://styles/sfarley2/cl3owg6lj000015ohqojkcmqv",
            center: [-92.41, 45.51],
            zoom: 3.5,
            hash: true
        });

        map.current.on('load', () => {
            console.log('load')
            map.current.addSource('origin', { type: 'geojson', data: { "type": "FeatureCollection", features: [] } });
            map.current.addSource('forecast', { type: 'geojson', data: { "type": "FeatureCollection", features: [] } });
            map.current.addSource('groundtruth', { type: 'geojson', data: { "type": "FeatureCollection", features: [] } });

            map.current.addLayer({
                'id': 'origin-fill',
                'source': 'origin',
                'type': 'fill',
                'paint': {
                    'fill-color': 'black',
                    'fill-opacity': 0.5
                }
            });
            map.current.addLayer({
                'id': 'origin-line',
                'source': 'origin',
                'type': 'line',
                'paint': {
                    'line-color': 'white',
                    'line-opacity': 1
                }
            });

            map.current.addLayer({
                'id': 'forecast-line',
                'source': 'forecast',
                'type': 'line',
                'paint': {
                    'line-color': 'orange',
                    'line-opacity': 0.9,
                    'line-width': 0.7
                }
            });

            map.current.addLayer({
                'id': 'groundtruth-line',
                'source': 'groundtruth',
                'type': 'line',
                'paint': {
                    'line-color': 'magenta',
                    'line-opacity': 0.9,
                    'line-width': 2
                }
            });
        })
    }, []);

    function setResults() {
        if (!props.results) {
            map.current.getSource('groundtruth').setData({ "type": "FeatureCollection", features: [] })
            map.current.getSource('forecast').setData({ "type": "FeatureCollection", features: [] })
            map.current.getSource('origin').setData({ "type": "FeatureCollection", features: [] })

        } else {
            map.current.getSource('groundtruth').setData({
                "type": "FeatureCollection", features: [
                    props.results.groundtruth_perimeter
                ]
            })
            map.current.getSource('origin').setData({
                "type": "FeatureCollection", features: [
                    props.results.original_perimeter
                ]
            })
            map.current.getSource('forecast').setData(props.results.modeled_perimeter);
            const box = Turf.bbox(props.results.modeled_perimeter);
            map.current.fitBounds([[box[0], box[1]], [box[2], box[3]]], {padding: 50, duration: 500});
        }
    }

    useEffect(() => {
        if (map.current.loaded()) {
            setResults()
        } else {
            map.current.once('idle', () => {
                setResults();
            })
        }



    }, [props.results]);

    return (
        <div className='map-outer-monitoring'>
            <div ref={mapContainer} className="map-container-monitoring" />
            <Box sx={{ position: 'absolute', left: 0, top: 0, zIndex: 250 }}>
                <Grid container >

                    <Grid item xs={12}><Box height={12} width={12} sx={{ backgroundColor: '#e67300', display: 'inline-block' }}></Box><Typography variant='caption' sx={{ display: 'inline', paddingLeft: 1 }}>Forecast (model perimeter)</Typography></Grid>
                    <Grid item xs={12}><Box height={12} width={12} sx={{ backgroundColor: 'pink', display: 'inline-block' }}></Box><Typography variant='caption' sx={{ display: 'inline', paddingLeft: 1 }}>Groundtruth (actual perimeter)</Typography></Grid>
                    <Grid item xs={12}><Box height={12} width={12} sx={{ backgroundColor: 'gray', display: 'inline-block' }}></Box><Typography variant='caption' sx={{ display: 'inline', paddingLeft: 1 }}>Burning at Start of Forecast</Typography></Grid>

                </Grid>
            </Box>
        </div>
    );
}

function judgeIOU(iou) {
    if (iou < 0.4) {
        return "Poor";
    } else if (iou < 0.6) {
        return "Fair";
    } else if (iou < 0.8) {
        return "Good";
    } else {
        return "Excellent";
    }
}

function judgeBearingDiff(a, b) {
    let normA = a % 360;
    let normB = b % 360;
    if (normA < 0) {
        normA += 360;
    }
    if (normB < 0) {
        normB += 360;
    }

    const delta = normA - normB;
    const aDelta = Math.abs(delta);
    if (aDelta < 45) {
        return "Excellent";
    } else if (aDelta < 90) {
        return "Good";
    } else if (aDelta < 180) {
        return "Fair";
    } else {
        return "Poor";
    }
}

export default function GroundtruthSnapshotView() {

    const [result, setResult] = useState(null);
    const [dates, setDates] = useState([]);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const params = useParams();

    const incidentID = params.incidentID;
    const idt = params.dt;

    const [dt, setDt] = useState(idt);

    useEffect(() => {
        const fetchData = async () => {
            const req = await fetch(`${GROUNDTRUTH_HOST}/groundtruth/${incidentID}`, {method: 'GET'});
            const res = await req.json();
            setDates(res);
        }
        fetchData();
    }, []);
    useEffect(() => {

        const fetchData = async () => {
            const req = await fetch(`${GROUNDTRUTH_HOST}/groundtruth/${incidentID}/${dt}`, {method: "POST"});
            if (req.status != 200) {
                setError("Failed to fetch forecast. Try another date.");
                setResult(null);
                return;
            }
            const res = await req.json();
            console.log(res)
            setResult(res);
        }
        fetchData();
    }, [dt, incidentID]);

    const handleDateChange = (evt) => {
        const targetDate = evt.target.value;
        setResult(null);
        setError(null);
        setDt(targetDate);
    }

    return (
        <Grid container sx={{ mx: 4, my: 4, maxHeight: '100vh', overflowY: 'auto' }}>

            <Grid item xs={6}>
                <Grid item xs={12} >
                    <Button onClick={() => { navigate(-1) }}>Back</Button>
                </Grid>
                <Grid item xs={12}>
                    <Typography>Other Model Runs:
                        <Select onChange={handleDateChange} value={dt}>
                            {dates.map((d, i) => {
                                return <MenuItem key={i} value={d.polygon_date}>{d.polygon_date}</MenuItem>
                            })}
                        </Select></Typography>

                </Grid>
                {
                    (error) ? (
                        <>
                            <Typography variant='subtitle1'>Error Fetching Forecast</Typography>
                            <Typography variant='subtitle1'>{error}</Typography>
                        </>
                    ) : null
                }
                {
                    (result && !error) ? (
                        <>
                            <Grid item xs={12} sx={{ my: 1 }}>
                                <Typography variant='h4'>{result.incident_name}</Typography>
                            </Grid>
                            <Grid item xs={12} sx={{ my: 1 }}>
                                <Typography variant='subtitle1'>{result.forecast_hours} Hour Forecast comparison for {result.comparison_time}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant='h5'>Summary Statistics</Typography>
                                <Typography variant='h6'>Average IoU: {result.statistics.iou.toFixed(2)} ({judgeIOU(result.statistics.iou)})</Typography>
                                <Grid item xs={12} sx={{ mt: 3 }}>
                                    <Typography variant='caption'>Area Measures</Typography>
                                    <Typography variant='subtitle1'>Forecast Area: {(result.statistics.model_area * 0.000247105).toLocaleString(undefined, { maximumFractionDigits: 0 })} acres</Typography>
                                    <Typography variant='subtitle1'>Groundtruth Area: {(result.statistics.groundtruth_area * 0.000247105).toLocaleString(undefined, { maximumFractionDigits: 0 })} acres</Typography>
                                    <Typography variant='subtitle1'>Forecast Delta: {(result.statistics.delta_area * 0.000247105).toLocaleString(undefined, { maximumFractionDigits: 0 })} acres</Typography>
                                </Grid>
                                <Grid item xs={12} sx={{ mt: 3 }}>
                                    <Typography variant='caption'>Shape Similarity Measures</Typography>
                                    <Typography variant='subtitle1'>Intersection Area (shared): {(result.statistics.intersection * 0.000247105).toLocaleString(undefined, { maximumFractionDigits: 0 })} acres</Typography>
                                    <Typography variant='subtitle1'>Union Area (distinct): {(result.statistics.union * 0.000247105).toLocaleString(undefined, { maximumFractionDigits: 0 })} acres</Typography>
                                </Grid>
                                <Grid item xs={12} sx={{ mt: 3 }}>
                                    <Typography variant='caption'>Center of Mass Change Measures</Typography>
                                    <Typography variant='subtitle1'>Model Distance / Groundtruth Distance: {(result.vectors.model.distance).toLocaleString(undefined, { maximumFractionDigits: 0 })} m / {(result.vectors.groundtruth.distance).toLocaleString(undefined, { maximumFractionDigits: 0 })}  m</Typography>
                                    <Typography variant='subtitle1'>Model Bearing / Groundtruth Bearing: {(result.vectors.model.bearing).toLocaleString(undefined, { maximumFractionDigits: 0 })}° / {(result.vectors.groundtruth.bearing).toLocaleString(undefined, { maximumFractionDigits: 0 })}° ({judgeBearingDiff(result.vectors.model.bearing, result.vectors.groundtruth.bearing)})</Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={6} sx={{ mt: 2 }}>
                                <ReactSpeedometer value={result.statistics.iou} currentValueText={result.statistics.iou.toFixed(2)} maxValue={1} minValue={0} width={200} />
                            </Grid>
                        </>
                    ) : <CircularProgress />
                }
            </Grid>
            <Grid item xs={6} sx={{ my: 1 }}>

                <GroundtruthMap results={result}></GroundtruthMap>

            </Grid>



        </Grid >
    )
}