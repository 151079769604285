import Snackbar from '@mui/material/Snackbar';
import * as React from 'react';
// import Alert from '@mui/material/Alert';
import MuiAlert from '@mui/material/Alert';


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });


export default function AppSnackbar(props) {
    return (
        <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}

            open={props.open}
            autoHideDuration={5000}
            severity={props.severity}
            onClose={props.onClose}
        >
            <Alert severity={props.severity} sx={{ width: '100%' }}>
                {props.message}
            </Alert>
        </Snackbar>

    );
}