import { TextField, Grid, Typography, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {useAuth0} from '@auth0/auth0-react';

const domain = "dev-70r4n0a5.us.auth0.com";

async function validateToken(token) {
    const url = `https://api-core-production-63vbfoxayq-uw.a.run.app/_internal/management/redeem?token=${token}`;
    const res = await fetch(url, { method: "PUT" });
    return res.status === 200;
}

/*
    - redeem token
    - mark user as active in the database
    - if login > 1, check that user is active before allowing continue
*/

export default function ActivationCodeChecker() {
    const [activationCode, setActivationCode] = useState('');
    const [error, setError] = useState('');
    const [authState, setAuthState] = useState('');

    useEffect(() => {
        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });
        setAuthState(params.state);
    }, []);

    const handleSuccess = () => {
        const url = `https://${domain}/continue?state=${authState}&activationToken=${activationCode}&result=success`;
        window.location.replace(url);
    }

    const handleContinue = async (evt) => {
        const isValidToken = await validateToken(activationCode);
        if (isValidToken) {
            handleSuccess();
        } else {
            setError("Invalid activation token");
        }
    }

    return (<Grid container sx={{ 'width': '100%', 'height': '100vh', position: 'absolute', top: 0, left: 0, zIndex: 25 }} alignContent={'center'} justifyContent='center' alignItems={'center'}>
        <Grid item xs={12} sx={{ 'width': '100%' }} alignContent={'center'} justifyContent='center' alignItems={'center'}>
            <Typography alignContent={'center'} sx={{ textAlign: 'center' }} variant='h6'>Enter Activation Code</Typography>
            <TextField alignContent={'center'} sx={{ textAlign: 'center', mt: 2, width: '50%', left: '25%' }} onError={(error) ? true : false}
                value={activationCode}
                onChange={(e) => { setActivationCode(e.target.value) }}
                label={"Code"}
                helperText={error}
            ></TextField>
        </Grid>
        <Grid item xs={12} sx={{ 'width': '100%', mt: 2, textAlign: 'center' }} alignContent={'center'} justifyContent='center' alignItems={'center'}>
            <Button alignContent={'center'} sx={{ textAlign: 'center' }} variant='contained' color='success' onClick={handleContinue}>Continue</Button>
        </Grid>
    </Grid>)
}