import { useAuth0 } from '@auth0/auth0-react';
import { Edit, FileCopy } from "@mui/icons-material";
import { Box, Button, TextField, Typography, Slider, Stack, Tooltip, FormControlLabel, Switch } from "@mui/material";
import { useState, useEffect } from 'react';
import { events, logAnalyticsEvent } from './services/firebase';
import * as React from 'react';

const MONITORING_HOST = `https://api-core-production-63vbfoxayq-uw.a.run.app`
// const MONITORING_HOST = 'http://localhost:8000';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: 5,
};

export default function EditPolicyModal(props) {

    const { user } = useAuth0();


    const [file, setFile] = useState(false);
    const [name, setName] = useState("");
    const [isUploading, setIsUploading] = useState(false);
    const [isComplete, setIsComplete] = useState(false);
    const [distanceThreshold, setDistanceThreshold] = useState(250);
    const [valueThreshold, setValueThreshold] = useState(100);
    const [countThreshold, setCountThreshold] = useState(100);
    const [alerting, setAlerting] = useState(true);
    const [fresh, setFresh] = useState(true);

    useEffect(() => {
        setName(props.name);
        setDistanceThreshold(props.distance_threshold);
        setValueThreshold(props.value_threshold);
        setCountThreshold(props.count_threshold);
        setAlerting(props.alerting_enabled);
    }, [])

    const updatePolicy = async () => {
        const baseUrl = `${MONITORING_HOST}/policies/${user.email}/${props.id}`
        const payload = {
            name,
            distance_threshold: distanceThreshold,
            value_threshold: valueThreshold,
            count_threshold: countThreshold,
            alerting_enabled: alerting
        }
        try {
            const req = await fetch(baseUrl, {
                body: JSON.stringify(payload), method: 'PUT', headers: {
                    'Content-Type': 'application/json'
                }
            });
            const res = await req.json();
            if (req.status > 204) {
                throw new Error("Unexpected status code");
            }
            logAnalyticsEvent(events.update_policy, { user: user.email, data: payload });
            props.onClose()
        } catch (err) {
            console.error(err);
            logAnalyticsEvent(events.upload_policy_error, { user: user, error: err });
            alert("There was an error uploading your file");
        } finally {
            setIsUploading(false);
        }
    }

    return (
        <Box sx={style}>
            <Typography><Edit></Edit> Edit Policy</Typography>
            <TextField error={(!fresh && !name)} helperText={(!fresh && !name) ? "Name cannot be empty" : ""}
                value={name}
                placeholder={"New Policy Name"} fullWidth onChange={(e) => {
                    setFresh(false)
                    setName(e.target.value)
                }} sx={{ my: 3 }}></TextField>

            <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                <Tooltip title="Maximum distance from forecast fire front to trigger email alerts">
                    <Typography>Distance Threshold: {distanceThreshold} meters</Typography>
                </Tooltip>
                <Slider valueLabelDisplay="auto" onChange={(e) => { setDistanceThreshold(e.target.value) }} value={distanceThreshold} step={10} max={2000}></Slider>
            </Stack>

            <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                <Tooltip title="Minimum threatened value to trigger email alerts">
                    <Typography>Value Threshold: ${valueThreshold.toLocaleString()}</Typography>
                </Tooltip>

                <Slider valueLabelDisplay="auto" onChange={(e) => { setValueThreshold(e.target.value) }} value={valueThreshold} step={1000} max={1000000} ></Slider>
            </Stack>

            <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                <Tooltip title="Minimum number of threatened properties to trigger email alerts">
                    <Typography>Count Threshold: {countThreshold}</Typography>
                </Tooltip>
                <Slider valueLabelDisplay="auto" onChange={(e) => { setCountThreshold(e.target.value) }} value={countThreshold} step={1} max={100}></Slider>
            </Stack>


            <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                <FormControlLabel control={<Switch color='primary' checked={alerting} onChange={() => { setAlerting(!alerting) }} />} label="Email Alerting Enabled" />

            </Stack>

            <Button color='secondary' sx={{ mt: 2 }} onClick={props.onClose}>Cancel</Button>
            <Button color='success' variant='contained' onClick={updatePolicy}>Finish Editing</Button>
        </Box>
    )
}