const defaultSettings = {
    simulation: {
        running: false,
        started: null,
        elapsed: null,
        duration: 48,
        useForecast: true,
        useTreatment: false,
        treatmentWidth: 12.5,
        forecast_provider: 'all',
        compute_smoke: false,
        simulation_name: null,
        simulation_context: null,
        weather: {
            windSpeed: 6,
            windDirection: 23,
            temperature: 89,
            relativeHumidity: 50
        },
        treatmentPoints: [],
        deadFuelMoisture: 20,
        liveFuelMoisture: 150,
        spotProbability: 0.02,
        forecastVersion: 2,
        resolution: {
            distance: 30,
            perimeter: 30,
            landscape: 30
        },
        ignitionFeatureName: null,
        ignition: null,
        treatment: null,
        treatmentType: null,
        count: 5,
        timestep: 60
    },
    history: {
        loading: false,
        results: []
    },
    application: {
        loading: true,
        mapRendering: true,
        playback_view: 'probability',
        map: {
            mode: null,
            style: "mapbox://styles/sfarley2/ckzsiviti003j14ld8lnt7chf"
        },
        mapFilter: 2,
        snackbar: {
            text: "",
            style: "success"
        },
        toolbar: {
            open: true
        },
        loadingStructures: false,
        geocontext: null,
        user: null,
        accessToken: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjoic2NvdHRzZmFybGV5IiwiZXhwIjoxNjcyNTYwMDAwLjB9.HxaszMdKqsi9gcYs99Rz_McXRULvOWcJAk7PG9yiFa0",
        flags: {
            allowUseTreatment: true,
            allowUseCustomWeather: true,
            allowUseGFSForecast: false,
            allowUseNWSForecast: true,
            allowUseOpenWeatherForecast: true,
            allowUseTomorrowIoForecast: true
        }
    }
}

export default defaultSettings