import DownloadIcon from '@mui/icons-material/Download';
import { Button } from '@mui/material';
import * as React from 'react';


export default function JSONDownloadButton(props) {
    return (
        <Button
            type="button"
            
            href={(props.url) ? props.url : `data:text/json;charset=utf-8,${encodeURIComponent(
                JSON.stringify(props.content)
            )}`}
            target="_blank"
            download={props.filename}
        >
            <DownloadIcon />
        </Button>
    )
}