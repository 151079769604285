import { Grid, Typography } from '@mui/material';
import AuthenticationButton from "./components/authButton";
import Logo from "./Logo";
import React, { useState, useEffect } from 'react';


export default function Splash() {
    const [errorState, setErrorState] = useState(false);

    useEffect(() => {
        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });
        setErrorState(params.error_description);
    }, []);

    return <Grid container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ backgroundColor: 'primary' }}
        style={{ minHeight: '100vh' }}>
        <Grid item xs={12}>

            <Logo></Logo>
        </Grid>


        {
            (errorState) ? (
                <Grid item xs={12} >
                    <Typography variant='h6' sx={{ flexGrow: 1, textAlign: 'center' }}>There was an error logging in.</Typography>
                    <Typography variant='subtitle1' sx={{ flexGrow: 1, textAlign: 'center' }} color='error'>{errorState}</Typography>
                </Grid>
            ) : (
                <Grid item xs={12} >
                    <Typography variant='h5' sx={{ flexGrow: 1, textAlign: 'center' }}>Welcome to Willow Studio!</Typography>
                    <Typography variant='subtitle1' sx={{ flexGrow: 1, textAlign: 'center' }}>Please log-in to continue.</Typography>
                </Grid>
            )
        }
        <Grid item xs={12} sx={{ flexGrow: 1, textAlign: 'center' }} alignItems="center"
            justifyContent="center" alignContent='center'>
            <AuthenticationButton></AuthenticationButton>
        </Grid>
        <Grid item xs={12} sx={{ flexGrow: 1, textAlign: 'center', maxWidth: 75, marginTop: 3 }} alignItems="center" justifyContent="center" alignContent='center'>
            <i style={{ fontSize: 'x-small' }}>By continuing, you are agreeing our <a style={{ color: 'darkcyan' }} target='_blank' href='https://willow-labs.co/legal/terms' rel="noreferrer">Terms of Use</a> and <a style={{ color: 'darkcyan' }} target='_blank' href='https://willow-labs.co/legal/privacy' rel="noreferrer">Privacy Policy</a>.</i>
        </Grid>
    </Grid>
}