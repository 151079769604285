import { ChevronLeftRounded, RemoveCircle } from "@mui/icons-material";
import { Box, Button, ButtonGroup, Divider, List, ListItem, ListItemText, Skeleton, Typography } from "@mui/material";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import AppSlider from './components/slider';
import defaultResults from "./defaults/results";
import defaultSettings from "./defaults/settings";
import { events, logAnalyticsEvent } from './services/firebase';
import * as React from 'react';


export default function ToolPanel(props) {
    const [fmValue, setfmValue] = useState(0);
    const navigate = useNavigate();

    const handleCountChange = (v) => {
        const newSettings = {
            simulation: {
                count: v
            }
        }
        logAnalyticsEvent(events.set_realization_count, { count: v })
        props.updateSettings(newSettings);
    }

    const handleDeadFMChange = (v) => {
        const newSettings = {
            simulation: {
                deadFuelMoisture: v
            }
        }
        props.updateSettings(newSettings);
    }

    const handleLiveFMChange = (v) => {
        const newSettings = {
            simulation: {
                liveFuelMoisture: v
            }
        }
        props.updateSettings(newSettings);
    }

    const handleDurationChange = (v) => {
        const newSettings = {
            simulation: {
                duration: v
            }
        }
        props.updateSettings(newSettings);
        logAnalyticsEvent(events.set_duration, { duration: v })

    }

    const handleReset = (v) => {

        const newSettings = defaultSettings;
        newSettings.simulation.ignition = null;
        props.updateSettings(newSettings);
        const newResults = defaultResults;
        props.updateResults(newResults);
        logAnalyticsEvent(events.simulation_params_reset)

    }

    const handleStartSimulation = (v) => {
        const newSettings = {
            simulation: {
                running: true,
                started: new Date().toISOString()
            }
        }
        props.updateSettings(newSettings);
        logAnalyticsEvent(events.run_simulation_start)

    }

    const handleIgnitionOnMapSelect = () => {
        const newSettings = {
            application: {
                map: {
                    mode: 'ignition'
                }
            }
        }
        props.updateSettings(newSettings);
        logAnalyticsEvent(events.set_ignition_point)
    }

    const handleForecastProviderChange = (forecast_provider) => {
        const newSettings = {
            simulation: {
                forecast_provider
            }
        }
        props.updateSettings(newSettings);
        logAnalyticsEvent(events.set_weather_provider, { provider: forecast_provider })

    }


    const handleIgnitionFileLoad = (perim) => {
        const newSettings = {
            simulation: {
                ignition: perim
            }
        }
        props.updateSettings(newSettings);
    }

    const handleTreatmentSelectOnMap = () => {
        const newSettings = {
            application: {
                map: {
                    mode: 'treatment'
                }
            }
        }
        props.updateSettings(newSettings);
    }

    const handleTreatmentTypeChange = (e) => {
        const v = e.target.value;
        const newSettings = {
            simulation: {
                treatmentType: v
            }
        }
        props.updateSettings(newSettings);
    }

    const handleSmokeComputeChange = (e) => {
        const nowValue = props.settings.simulation.computeSmoke;
        const newValue = !nowValue;
        const newSettings = {
            simulation: {
                computeSmoke: newValue
            }
        }
        props.updateSettings(newSettings);
    }

    const handleTreatmentWidthChange = (v) => {
        const newSettings = {
            simulation: {
                treatmentWidth: v
            }
        }
        props.updateSettings(newSettings);
    }

    const handleIgnitionReset = () => {
        const newSettings = {
            simulation: {
                ignition: null
            }
        }
        props.updateSettings(newSettings);
    }

    const handleLowWx = () => {
        const newSettings = {
            simulation: {
                weather: {
                    windSpeed: 2
                },
                liveFuelMoisture: 150,
                deadFuelMoisture: 20
            }
        }
        setfmValue(0);
        props.updateSettings(newSettings);
    };


    const handleMediumWx = () => {
        const newSettings = {
            simulation: {
                weather: {
                    windSpeed: 12
                },
                liveFuelMoisture: 90,
                deadFuelMoisture: 12
            }
        }
        setfmValue(1);
        props.updateSettings(newSettings);
    }
    const handleHighWx = () => {
        const newSettings = {
            simulation: {
                weather: {
                    windSpeed: 25
                },
                liveFuelMoisture: 50,
                deadFuelMoisture: 8
            }
        }
        setfmValue(2);
        props.updateSettings(newSettings);
    }

    return (
        <List sx={{ overflowY: 'auto', maxHeight: '90vh', p: 1, textAlign: 'left' }}>
            {(props.settings.simulation.running) ? (
                <>
                    <ListItem>
                        <ListItemText>
                            <Typography variant='h3'>Running Simulation...</Typography>
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <Skeleton animation="wave" height={10} width="40%" />
                    </ListItem>
                    <ListItem>
                        <Skeleton animation="wave" height={10} width="40%" />
                    </ListItem>
                    <ListItem>
                        <Typography variant='caption'>Most simulations complete within 5 minutes, but fires with high potential for growth may take longer.</Typography>
                    </ListItem>
                    <ListItem>
                        <Button color='warning' onClick={() => {
                            handleReset()
                        }} endIcon={<RemoveCircle></RemoveCircle>} >Cancel</Button>
                    </ListItem>
                </>
            ) : (<>
                <Button onClick={() => { navigate(-1) }} startIcon={<ChevronLeftRounded />}>Back</Button>


                <Typography variant='h4' sx={{ p: 2 }}>Create New Simulation</Typography>
                <Box sx={{ p: 2 }}>

                    <Button style={{ display: 'inline', fontSize: '10px' }} disabled={(props.settings.simulation.ignition) ? false : true} onClick={handleStartSimulation} variant='contained' color='success' size="small">Start</Button>

                    <Button style={{ display: 'inline', fontSize: '10px', marginLeft: '6px' }} disabled={(props.settings.simulation.running)} onClick={handleReset} color='secondary' variant='contained' size="small">Reset</Button>

                    <Typography variant='h6' sx={{ mt: 1 }}>Ignition</Typography>
                    {(!props.settings.application.map.mode && !props.settings.simulation.ignition) ? (
                        <Button size="small" sx={{ mt: 0, width: '100%' }} variant="elevated" onClick={handleIgnitionOnMapSelect}>Set on Map</Button>
                    ) : null}
                    {(props.settings.application.map.mode && !props.settings.simulation.ignition) ? (
                        <Typography variant='subtitle1'>Click on Map to Set Ignition</Typography>
                    ) : null}
                    {(!props.settings.application.map.mode && props.settings.simulation.ignition && props.settings.simulation.ignitionFeatureName) ? (
                        <Typography variant='body2'>Incident: {props.settings.simulation.ignitionFeatureName}</Typography>
                    ) : <>
                        {/* <Typography variant='body2'>Longitude: {props.settings.simulation.ignition.geometry.coordinates[0][0].toFixed(5)}</Typography> */}
                        {/* <Typography variant='body2'>Latitude: {props.settings.simulation.ignition.geometry.coordinates[0][1].toFixed(5)}</Typography> */}
                    </>}

                    {(!props.settings.application.map.mode && props.settings.simulation.ignition) ? (
                        <Button onClick={handleIgnitionReset}>Reset Ignition</Button>
                    ) : null}
                    <Divider />
                    <Typography variant='h6' sx={{ mt: 2 }}>Run Settings</Typography>
                    <Box sx={{ display: 'flex', mt: 1 }}>
                        <Box sx={{ flexGrow: 2 }}>
                            <Typography variant='body1' sx={{ width: '100%', fontWight: 800 }}>Duration </Typography>
                            <Typography variant='body2' sx={{ width: '100%' }}>{props.settings.simulation.duration} hours</Typography>
                        </Box>
                        <Box sx={{ flexGrow: 2 }}>
                            <AppSlider onChange={handleDurationChange} value={props.settings.simulation.duration} min={6} max={72} label={"Duration"}></AppSlider>
                        </Box>
                    </Box>

                    <Box sx={{ display: 'flex', mt: 1 }}>
                        <Box sx={{ flexGrow: 2 }}>
                            <Typography variant='body1' sx={{ width: '100%', fontWight: 800 }}>Realizations</Typography>
                            <Typography variant='body2' sx={{ width: '100%' }}>{props.settings.simulation.count} realizations</Typography>
                        </Box>
                        <Box sx={{ flexGrow: 2 }}>
                            <AppSlider onChange={handleCountChange} value={props.settings.simulation.count} min={1} max={30} step={1} label={"Simulation Count"}></AppSlider>
                        </Box>
                    </Box>

                    <Divider />
                    <Box sx={{ display: 'flex', mt: 1 }}>
                        <Box sx={{ flexGrow: 2 }}>
                            <Typography variant='subtitle1'>Forecast Provider</Typography>
                        </Box>
                        <Box sx={{ flexGrow: 2 }}>

                            <FormControl fullWidth>
                                <InputLabel>Provider</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label2"
                                    id="demo-simple-select2"
                                    value={props.settings.simulation.forecast_provider}
                                    label="Provider"
                                    onChange={(e) => { handleForecastProviderChange(e.target.value) }}
                                >
                                    <MenuItem value={'all'}>All Providers (Monte Carlo)</MenuItem>
                                    {/* <MenuItem value={'gfs'}>NOAA Global Forecast Service</MenuItem> */}
                                    <MenuItem value={'nws'}>NWS National Digital Forecast Database</MenuItem>
                                    <MenuItem value={'tomorrowio'}>Tomorrow.io</MenuItem>
                                    <MenuItem value={'openweather'}>OpenWeather</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </Box>

                    <Box sx={{ display: 'flex', mt: 1 }}>
                        <Box sx={{ flexGrow: 2 }}>
                            <Typography variant='subtitle1' sx={{ width: '100%' }}>Live Fuel Moisture</Typography>
                        </Box>
                        <Box sx={{ flexGrow: 2, mx: 1 }}>
                            <ButtonGroup variant="elevated" aria-label="outlined primary button group">
                                <Button size="small" sx={{ fontSize: '10px' }} variant='contained' color={(fmValue == 0) ? 'primary' : 'background'} onClick={handleLowWx}>Early Season</Button>
                                <Button size="small" sx={{ fontSize: '10px' }} variant='contained' color={(fmValue == 1) ? 'primary' : 'background'} onClick={handleMediumWx}>Mid-Season</Button>
                                <Button size="small" sx={{ fontSize: '10px' }} variant='contained' color={(fmValue == 2) ? 'primary' : 'background'} onClick={handleHighWx}>late-Season</Button>
                            </ButtonGroup>
                        </Box>
                    </Box>
                </Box>
            </>)}
        </List>
    )
}