import { assignColors } from './../utils';
import * as Turf from '@turf/turf';

async function loadMonitoringProperties(userID, bbox) {
    const MONITORING_HOST = `https://api-core-production-63vbfoxayq-uw.a.run.app`

    const req = await fetch(`${MONITORING_HOST}/policies/${userID}/impacted?bbox=${bbox.join(",")}`);
    const res = await req.json();
    return res;
}

async function awaitJSON(url) {
    const req = await fetch(url);
    const res = await req.json();
    return res
}

export default async function fetchIncidentDetails(incidentID, userID) {
    const req = await fetch(`https://api-core-production-63vbfoxayq-uw.a.run.app/monitoring/incidents/${incidentID}?since_hours_ago=1000`);

    if (req.status > 400) {
        throw new Error("Incident forecast not found");
    }
    const data = await req.json();

    const bbox = Turf.bbox(data);
    bbox[0] -= 0.05;
    bbox[1] -= 0.05;
    bbox[2] += 0.05;
    bbox[3] += 0.05;

    const promises = [
        awaitJSON(data['properties']['structure_file_url']),
        awaitJSON(data['properties']['probability_file_url'].replace("perimeters", "probabilities")),
        awaitJSON(data['properties']['perimeter_file_url']),
        awaitJSON(data['properties']['forecast_file_url']),
        loadMonitoringProperties(userID, bbox)
    ]

    const results = await Promise.all(promises);

    return {
        perimeters: assignColors(results[2]),
        probabilities: results[1],
        structures: results[0],
        forecast: results[3],
        ignition: data,
        impactedProperties: results[4],
        urls: data.properties
    }
}