import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@mui/material';
import React from 'react';
import { events, logAnalyticsEvent } from '../services/firebase';

const LogoutButton = () => {
  const { logout } = useAuth0();
  return (
    <Button
      variant='elevated'
      onClick={() => {
        logAnalyticsEvent(events.log_out);
        logout({
          returnTo: window.location.origin,
        })
      }
      }
    >
      Log Out
    </Button>
  );
};

export default LogoutButton;